@media only screen and (max-width: 767px) {
    .ptb-80 {
        padding-bottom: 50px;
        padding-top: 50px;
   }
    p {
        font-size: 14px;
   }
    img {
        width: 100%;
   }
    .btn {
        font-size: 14px;
   }
    .owl-theme .owl-nav [class*="owl-"] {
        top: auto;
        bottom: 0;
        left: 0;
   }
    .owl-theme .owl-nav .owl-next {
        left: auto;
        right: 0;
   }
    .section-title {
        margin-bottom: 40px;
   }
    .section-title h4 {
        font-size: 16px;
        margin-bottom: 15px;
   }
    .section-title h2 {
        font-size: 28px;
   }
    .section-title p {
        font-size: 14px;
   }
    .navbar {
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
   }
    .navbar.navbar-light .navbar-brand {
        color: #000;
        padding-top: 0;
   }
    .navbar.navbar-light .navbar-brand:focus, .navbar.navbar-light .navbar-brand:hover {
        color: #000;
   }
    .navbar.navbar-light .navbar-toggler {
        border-color: #ff2c57;
   }
    .navbar.navbar-light .navbar-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 44, 87, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
   }
    .navbar.navbar-light .navbar-nav .nav-link {
        color: #000;
   }
    .main-banner {
        height: 100%;
        padding-top: 110px;
        padding-bottom: 110px;
   }
    .creative-banner-one {
        border-width: 5rem 100vw 0 0;
   }
    .creative-banner-two::before {
        border-top: 90px solid transparent;
   }
    .creative-banner-two::after {
        border-top: 90px solid transparent;
   }
    .creative-banner-three::before {
        height: 40px;
        background-size: cover;
        bottom: 0;
   }
    .main-banner-text h4 {
        font-size: 20px;
        letter-spacing: 0;
   }
    .main-banner-text h1 {
        font-size: 35px;
        margin-top: 15px;
        margin-bottom: 15px;
        letter-spacing: 0;
        line-height: 45px;
   }
    .main-banner-text p {
        font-size: 15px;
   }
    .main-banner-text .view-work {
        margin-left: 0;
   }
    .welcome-area {
        padding-bottom: 20px;
   }
    .welcome-area .section-title h2 {
        line-height: 1.3;
        margin-top: -3px;
   }
    .single-box {
        margin-bottom: 30px;
   }
    .single-box .icon {
        font-size: 35px;
   }
    .single-box h3 {
        margin-top: 20px;
        margin-bottom: 18px;
        font-size: 18px;
   }
    .about-gf365 .section-title h2 {
        line-height: 1.4;
        margin-top: -10px;
        font-size: 18px;
   }
    .about-gf365 .pull-left {
        margin-right: 0;
        float: none;
        margin-bottom: 10px;
   }
    .about-gf365 ul li {
        font-size: 14px;
   }
    .about-video {
        margin-top: 170px;
   }
   .client-pic{
    top: 5%;
    left: -8%;
  }

    .about-video .video-btn a {
        width: 60px;
        height: 60px;
        line-height: 65px;
        font-size: 25px;
   }
    .about-video .video-btn a::before, .about-video .video-btn a::after {
        height: 70px;
        width: 70px;
   }
    .strategy-area .image {
        display: none;
   }
    .about-strategy {
        padding-left: 0;
   }
    .about-strategy ul li {
        font-size: 14px;
   }
    .who-we-are {
        padding-bottom: 20px;
   }
    .single-who-we-are {
        padding: 35px 20px;
   }
    .single-who-we-are i {
        font-size: 35px;
   }
    .single-who-we-are h4 {
        font-size: 18px;
   }
    .ctr-area .section-title h2 {
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 1.3;
   }
    .ctr-area .section-title p {
        margin-bottom: 25px;
   }
    .about-skill .section-title {
        padding-left: 30px;
   }
    .about-skill .section-title::before, .about-skill .section-title::after {
        display: block;
   }
    .about-skill .section-title h2 {
        font-size: 29px;
   }
    .about-skill .progress-title {
        font-size: 15px;
        margin-bottom: 10px;
   }
    .about-skill .progress {
        height: 8px;
        margin-bottom: 20px;
   }
    .about-skill .progress .progress-value {
        font-size: 14px;
   }
    .skill-video {
        margin-top: 30px;
   }
    .skill-video .video-btn a {
        width: 60px;
        height: 60px;
        line-height: 65px;
        font-size: 25px;
   }
    .skill-video .video-btn a::before, .skill-video .video-btn a::after {
        height: 70px;
        width: 70px;
   }
    .funFacts-area {
        padding-bottom: 20px;
   }
    .funFact {
        margin-bottom: 30px;
        padding-left: 50px;
   }
    .funFact .fa.fa-users {
        left: 0;
        font-size: 35px;
        top: 5px;
   }
    .funFact i {
        font-size: 35px;
   }
    .funFact h2 {
        font-size: 30px;
   }
    .funFact p {
        font-size: 16px;
   }
    .team-area .section-title h2 {
        line-height: 1.3;
        margin-top: -4px;
   }
    .our-team .team-content .title {
        font-size: 18px;
        margin-bottom: 10px;
   }
    .our-team .team-content .post {
        font-size: 14px;
   }
    .how-work {
        padding-bottom: 20px;
   }
    .single-how-work {
        padding: 30px;
        margin-bottom: 30px;
   }
    .single-how-work h3 {
        font-size: 35px;
   }
    .single-how-work h4 {
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 18px;
   }
    .services-area {
        padding-bottom: 20px;
   }
    .single-services .services-content {
        padding: 30px 20px;
   }
    .single-services .services-content h3 {
        font-size: 18px;
        margin-bottom: 15px;
   }
    .why-we-different .section-title h2 {
        line-height: 1.3;
        display: inline-block;
        margin-top: -5px;
   }
    .tabset label {
        display: block;
        padding: 14px 20px;
        margin-right: 0;
        margin-bottom: 5px;
        border-radius: 5px;
        font-size: 16px;
   }
    .tab-panel .why-we-different-text h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
   }
    .tab-panel .why-we-different-text ul {
        margin-bottom: 25px;
   }
    .tab-panel .why-we-different-text ul li {
        font-size: 14px;
   }
    .tab-panel .why-we-different-img {
        margin-top: 35px;
   }
    .shorting-menu {
        display: block;
        box-shadow: unset;
        padding: 0;
        text-align: center;
        border-radius: 0;
   }
    .shorting-menu .filter {
        padding: 8px 22px;
        font-size: 14px;
        margin-bottom: 7px;
   }
    .shorting {
        margin-top: 25px;
   }
    .shorting .single-work {
        padding: 0;
        margin: 0;
   }
    .shorting .single-work:hover::before {
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
   }
    .shorting .single-work .work-content {
        bottom: -95px;
        width: 100%;
        left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
   }
    .shorting .single-work .work-content h4 {
        font-size: 18px;
   }
    .shorting .single-work .popup-btn {
        top: 5px;
        right: 15px;
   }
    .shorting .single-work:hover .work-content {
        bottom: 0;
   }
    .shorting .single-work:hover .popup-btn {
        top: 15px;
   }
    .price-area {
        padding-bottom: 20px;
   }
    .price-area .section-title h2 {
        line-height: 1.3;
        display: inline-block;
        margin-top: -5px;
   }
    .pricingTable {
        margin-bottom: 30px;
        padding: 20px 0;
   }
    .pricingTable .title {
        padding: 18px 0;
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 500;
   }
    .pricingTable .price-value .currency {
        font-size: 18px;
   }
    .pricingTable .price-value .amount {
        font-size: 30px;
   }
    .pricingTable .price-value .month {
        font-size: 14px;
        line-height: 15px;
        margin-top: 5px;
   }
    .pricingTable::after {
        width: 55px;
        height: 20px;
        top: 73px;
   }
    .pricingTable .pricing-content {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
   }
    .pricingTable .pricing-content li {
        margin-top: 14px;
        font-size: 14px;
   }
    .faq-area .section-title h2 {
        line-height: 1.3;
        display: inline-block;
        margin-top: -5px;
   }
    .faq-area .image {
        display: none;
   }
    .card .card-header a h5 {
        font-size: 15px;
        padding: 10px;
        line-height: 25px;
   }
    .card .card-header i {
        display: none;
   }
    .card .card-body {
        font-size: 14px;
   }
    .testimonials-area {
        padding-bottom: 20px;
   }
    .testimonials-area .section-title h2 {
        display: inline-block;
        line-height: 1.3;
        margin-top: -5px;
   }
    .single-feedback {
        padding: 20px;
   }
    .single-feedback .client-info {
        margin-bottom: 30px;
        position: relative;
        overflow: unset;
        padding-left: 95px;
   }
    .single-feedback .client-info .client-pic {
        float: unset;
        position: absolute;
        left: 0;
        top: -13px;
   }
    .single-feedback .client-info .client-title {
        float: unset;
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
   }
    .single-feedback .client-info .client-title h4 {
        font-size: 18px;
   }
    .single-feedback .client-info .client-title h5 {
        font-size: 14px;
        margin-top: 10px;
   }
    .single-feedback span {
        font-size: 100px;
        right: 15px;
   }
    .single-feedback p {
        margin-top: 40px;
   }
    .single-blog-item span {
        font-size: 14px;
   }
    .single-blog-item h4 {
        font-size: 18px;
   }
    .subscribe-area {
        text-align: left;
   }
    .newsletter {
        padding: 30px 20px;
        text-align: center;
   }
    .newsletter h4 {
        margin-bottom: 20px;
        font-size: 22px;
   }
    .newsletter form .form-control {
        height: 60px;
        border-radius: 30px;
        padding-left: 15px;
        font-size: 14px;
   }
    .newsletter form .btn {
        position: unset;
        right: 0;
        top: 0;
        height: 50px;
        width: auto;
        margin-top: 20px;
   }
    .contact-info {
        padding: 25px 25px 30px;
   }
    .contact-info ul li {
        font-size: 14px;
   }
    .contact-info ul li:first-child i {
        top: 0;
   }
    .contact-form {
        padding: 40px 25px;
        margin-top: 30px;
   }
    .contact-form label {
        font-size: 14px;
   }
    .page-title {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
   }
    .page-title h1 {
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 24px;
   }
    .page-title ul li {
        font-size: 14px;
        text-transform: capitalize;
   }
    .page-title ul li a {
        font-size: 14px;
   }
    .blog-details-area .title {
        margin-top: 25px;
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 1.4;
   }
    .blog-details .article-img .date {
        bottom: 5px;
        width: 70px;
        left: 5px;
        height: 70px;
        font-size: 18px;
        padding-top: 10px;
        line-height: 25px;
   }
    .blog-details .article-text {
        padding: 25px 15px;
   }
    .blog-details .article-text ul li {
        margin-bottom: 5px;
   }
    .blog-details .article-text .category a {
        font-size: 14px;
   }
    .blog-details .article-text .blockquote {
        padding: 15px;
        font-size: 15px;
        border-left: 3px solid #ff2c57;
   }
    .blog-details .article-text .author-share .article-author {
        overflow: unset;
        position: relative;
        padding-left: 105px;
   }
    .blog-details .article-text .author-share .article-author .author-img {
        float: unset;
        width: 100%;
        position: absolute;
        left: 0;
        top: -25px;
   }
    .blog-details .article-text .author-share .article-author .author-title {
        margin-top: 15px;
        float: unset;
        width: 100%;
        padding: 0;
   }
    .blog-details .article-text .author-share .article-author .author-title h4 {
        font-size: 17px;
   }
    .blog-details .article-text .author-share .article-author .author-title span {
        font-size: 14px;
   }
    .blog-details .article-text .author-share .social-share {
        text-align: center;
        margin-top: 35px;
   }
    .blog-details .article-text .author-share .social-share a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
   }
    .post-controls-buttons {
        padding-left: 0;
        padding-right: 0;
   }
    .post-comments .single-comment {
        padding: 20px 15px 20px 110px;
        margin-bottom: 20px;
        overflow: unset;
        position: relative;
   }
    .post-comments .single-comment .comment-img {
        float: unset;
        width: auto;
        position: absolute;
        left: 10px;
        top: 20px;
   }
    .post-comments .single-comment .comment-content {
        float: unset;
        width: 100%;
        padding-left: 0;
   }
    .post-comments .single-comment .comment-content h4 {
        font-size: 17px;
        text-transform: uppercase;
        margin-bottom: 15px;
   }
    .post-comments .single-comment .comment-content span {
        font-size: 14px;
   }
    .post-comments .single-comment .comment-content a {
        right: auto;
        bottom: 20px;
        font-size: 16px;
        left: 15px;
   }
    .leave-a-reply {
        padding: 20px 15px;
   }
    .footer-area {
        padding: 35px 0;
   }
    .footer-area h4 {
        line-height: 20px;
        margin-bottom: 25px;
   }
    .footer-area h4 a {
        font-size: 22px;
   }
    .footer-area p {
        font-size: 14px;
   }
    .footer-area ul li a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .owl-theme .owl-nav [class*="owl-"] {
        left: 0;
        top: auto;
        bottom: 20px;
   }
    .owl-theme .owl-nav .owl-next {
        left: auto;
        right: 0;
   }
    p {
        font-size: 15px;
   }
    .navbar {
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
   }
    .navbar.navbar-light .navbar-brand {
        color: #000;
   }
    .navbar.navbar-light:focus, .navbar.navbar-light:hover {
        color: #000;
   }
    .navbar.navbar-light .navbar-toggler {
        border-color: #ff2c57;
   }
    .navbar.navbar-light .navbar-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 44, 87, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
   }
    .navbar.navbar-light .navbar-nav .nav-link {
        color: #000;
   }
    .main-banner {
        height: 100%;
        padding-top: 120px;
        padding-bottom: 60px;
   }
    .creative-banner-two::before {
        border-top: 100px solid transparent;
   }
    .creative-banner-two::after {
        border-top: 100px solid transparent;
   }
    .creative-banner-three::before {
        height: 60px;
        background-size: cover;
        bottom: 0;
   }
    .main-banner-text h4 {
        font-size: 18px;
   }
    .main-banner-text h1 {
        font-size: 45px;
        margin-bottom: 25px;
   }
    .welcome-area {
        padding-bottom: 50px;
   }
    .single-box {
        margin-bottom: 30px;
   }
    .about-gf365 .section-title h2 {
        font-size: 22px;
   }
    .about-gf365 ul li {
        font-size: 15px;
   }
    .about-strategy ul li {
        font-size: 15px;
   }
    .about-video {
        margin-top: 40px;
   }
    .about-video img {
        width: 100%;
        margin-top: 100px;
   }
    .skill-video {
        margin-top: 40px;
   }
    .skill-video img {
        width: 100%;
   }
    .funFacts-area {
        padding-bottom: 50px;
   }
    .funFact {
        margin-bottom: 30px;
   }
    .how-work {
        padding-bottom: 50px;
   }
    .single-how-work {
        margin-bottom: 30px;
   }
    .tabset label {
        padding: 14px 15px;
        margin-right: 2px;
        font-size: 16px;
   }
    .why-we-different-img {
        margin-top: 58px;
   }
    .shorting-menu {
        display: block;
        padding: 15px 15px;
   }
    .single-work {
        padding: 0;
        margin: 0;
   }
    .single-work:hover::before {
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
   }
    .single-work .work-content {
        bottom: 0;
        width: 100%;
        left: 0;
   }
    .single-work:hover .work-content {
        bottom: 10px;
   }
    .price-area {
        padding-bottom: 50px;
   }
    .pricingTable {
        margin-bottom: 30px;
   }
    .faq-area .image {
        display: none;
   }
    .contact-form {
        margin-top: 40px;
   }
    .page-title {
        height: 430px;
   }
    .page-title h1 {
        margin-top: 20px;
        margin-bottom: 25px;
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-gf365 .pull-left {
        margin-right: 35px;
   }
    .single-who-we-are {
        padding: 35px 30px;
   }
    .about-skill .section-title {
        margin-bottom: 40px;
   }
    .skill-video {
        margin-top: 60px;
   }
    .single-feedback {
        padding: 30px 25px;
   }
    .single-feedback .client-title h5 {
        margin-top: 9px;
   }
    #map {
        height: 383px;
   }
}
@media only screen and (min-width: 1400px) {
    .main-banner {
        height: 550px;
   }
}
