@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");

/* Pretty Stuff */
.contact-form-container {
  background: #f4f3f3;
  font-family: "Lato", sans-serif;
}

.contact-us {
  position: relative;
  width: 250px;
  background: #c3e0ec;
  overflow: hidden;
}
.contact-us:before {
  position: absolute;
  content: "";
  bottom: -50px;
  left: -100px;
  height: 100%;
  width: 100%;
  background: #f8b7d8;
  transform: rotate(25deg);
}
.contact-us:after {
  position: absolute;
  content: "";
  bottom: -80px;
  right: -100px;
  height: 270px;
  width: 400px;
  background: #9ed8eb;
  transform: rotate(-25deg);
}

.contact-header {
  color: white;
  position: absolute;
  transform: rotate(-90deg);
  top: 120px;
  left: -40px;
}
.contact-header h1 {
  font-size: 1.5rem;
}

.social-bar {
  position: absolute;
  bottom: 20px;
  left: 75px;
  z-index: 1;
  width: 100px;
}
.social-bar ul {
  list-style-type: none;
}
.social-bar ul li {
  display: inline-block;
  color: white;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-right: -4px;
  font-size: 1.2rem;
}

.header {
  text-align: center;
  padding: 20px 0;
  color: #444;
}
.header h1 {
  font-weight: normal;
}
.header h2 {
  margin-top: 10px;
  font-weight: 300;
}

.address,
.email,
.phone {
  text-align: center;
  padding: 20px 0;
  color: #444;
}
.address h3,
.email h3,
.phone h3 {
  font-size: 1rem;
  font-weight: 300;
}
.address i,
.email i,
.phone i {
  color: #f8b7d8;
  font-size: 1.7rem;
  margin-bottom: 20px;
}

form#contact {
  position: relative;
  width: 440px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: white;
}
form#contact input,
form#contact textarea {
  background: white;
  display: block;
  margin: 20px auto;
  width: 100%;
  border: 0;
}
form#contact input {
  height: 40px;
  line-height: 40px;
  outline: 0;
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  font-size: 1rem;
  color: rgba(68, 68, 68, 0.8);
}
form#contact textarea {
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  resize: none;
  outline: none;
  font-size: 1rem;
  font-family: lato;
  color: rgba(68, 68, 68, 0.8);
}
form#contact button {
  display: block;
  height: 40px;
  width: 250px;
  left: 95px;
  border: 0;
  border-radius: 20px;
  bottom: -20px;
  background: #9ed8eb;
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  outline: none;
}

.contact-form {
  padding-bottom: 20px;
}

/* Layout Stuff */
.contact-container {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/38816/image-from-rawpixel-id-2042508-jpeg.jpg");
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  justify-items: center;
  align-items: center;
}

.contact-form-container {
  width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 2fr;
  grid-template-areas: "contact-us header header header" "contact-us contact-form contact-form contact-form";
}

.contact-us {
  grid-area: contact-us;
}

.header {
  grid-area: header;
}

.address {
  grid-area: address;
}

.phone {
  grid-area: phone;
}

.email {
  grid-area: email;
}

.contact-form {
  grid-area: contact-form;
}

@media only screen and (max-width: 816px) {
  .contact-form-container {
    width: auto;
    display: block;
  }
  .contact-us {
    width: 0;
  }
}
