@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

.login-container:before {
  content: "";
  position: fixed;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 55%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #4481eb 0%, #04befe 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}
.panel.left-panel {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.content {
  color: white;
  margin-bottom: 100px;
  width: fit-content;
}

.panel.left-panel img {
  margin-right: 5%;
}

@media (max-width: 768px) {
  .login-container:before {
    display: none;
  }

  .panel.left-panel img {
    display: none;
  }
  .content {
    width: 135%;
    background-color: #4481eb;
    margin-bottom: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .panel.left-panel {
    margin-top: 0px;
    position: relative;
    height: 120px;
  }
}

.multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 3px;
  padding: 10px;
  border-radius: 0 !important;
} 
