@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
}
ul li {
  list-style: none;
}
:focus {
  outline: 0 !important;
}
p {
  margin-bottom: 0;
  font-weight: 300;
  line-height: 1.8;
  color: #666;
}
a {
  color: #222;
  text-decoration: none;
  transition: 0.4s;
}
a:hover,
a:focus {
  color: #ff2c57;
  text-decoration: none;
}
img {
  max-width: 100%;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.ptb-80 {
  padding-bottom: 80px;
  padding-top: 80px;
}
.d-table {
  width: 100%;
  height: 100%;
  position: inherit;
}
.d-table-cell {
  vertical-align: middle;
}
.bg-f6f6f6 {
  background: #f6f6f6;
}
/*Btn Btn-Primary*/
.btn {
  padding: 9px 25px;
  font-size: 16px;
  border-radius: 30px;
  transition: 0.4s;
}
.btn-primary {
  color: #fff;
  background-color: #ff2c57;
  border-color: #ff2c57;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus {
  color: #ff2c57;
  background-color: #fff;
  border-color: #ff2c57;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ff2c57;
  border-color: #ff2c57;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff2c57;
  border-color: #ff2c57;
  opacity: 1;
}
/* Section Title */
.section-title {
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}
.section-title::before,
.section-title::after {
  content: "";
  /* position: absolute;
  width: 3px;
  height: 100%;
  left: 10px;
  top: 0;
  background: #ff2c57;
  transition: 0.4s; */
}
.section-title::after {
  left: 0;
  height: 50%;
}
.section-title:hover::before {
  height: 50%;
}
.section-title:hover::after {
  height: 100%;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 200;
  margin-bottom: 20px;
}
.section-title h2 span {
  color: #ff2c57;
  font-weight: 600;
}
.section-title p {
  max-width: auto;
  margin-top: -10px;
  margin-bottom: 10px;
}
.section-title h4 {
  font-size: 18px;
  font-weight: 300;
  color: #ff2c57;
  margin-bottom: 17px;
  text-transform: capitalize;
}
/* Form Control */
.form-control {
  height: 45px;
  padding: 10px;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bababa;
  border-radius: 0;
}
.form-control:focus {
  border-color: #ddd;
  outline: 0;
  box-shadow: none;
}
/* Owl Carousel Button */
.owl-theme .owl-nav {
  margin-top: 0;
  border-radius: 50%;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}
.owl-theme .owl-dots .owl-dot {
  margin-top: 50px !important;
}
.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
  margin: 0 4px !important;
  background: transparent !important;
  border-radius: 50% !important;
  border: 2px solid #ddd !important;
  transition: 0.4s !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #869791 !important;
  border-radius: 5px !important;
  width: 35px !important;
  height: 10px !important;
  border-color: #ff2c57 !important;
  background: #ff2c57 !important;
}
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff2c57 !important;
  border-color: #ff2c57 !important;
}
.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: 20px;
  left: 20px;
  margin-top: -50px;
  width: 30px;
  text-align: center;
  height: 30px;
  line-height: 24px;
  border-radius: 50% !important;
  font-size: 30px;
  color: #fff !important;
  transition: 0.4s;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #ff2c57 !important;
  color: #fff !important;
}
.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 40px;
}
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
}
.preloader .folding-cube {
  left: 0;
  width: 60px;
  height: 60px;
  position: absolute;
  transform: rotateZ(45deg);
  top: 50%;
  right: 0;
  margin: -60px auto 0;
}
.preloader .folding-cube .cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}
.preloader .folding-cube .cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff2c57;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.preloader .folding-cube .cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.preloader .folding-cube .cube2::before {
  animation-delay: 0.3s;
}
.preloader .folding-cube .cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.preloader .folding-cube .cube3::before {
  animation-delay: 0.6s;
}
.preloader .folding-cube .cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.preloader .folding-cube .cube4::before {
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
/* ============================== Main Header CSS ============================== */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header-sticky.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff !important;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0);
  font-weight: 600;
  text-transform: uppercase;
}
.navbar-light .navbar-brand span {
  color: #ff2c57;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #fff;
}
.navbar-light .navbar-nav .nav-item {
  padding-right: 15px;
  padding-left: 15px;
}
.navbar-light .navbar-nav .nav-item:last-child {
  padding-right: 0;
}
.navbar-light .navbar-nav .nav-item .nav-link {
  color: rgb(0, 0, 0);
  padding-right: 0;
  padding-left: 0;
}
.navbar-light .navbar-nav .nav-item .nav-link.active,
.navbar-light .navbar-nav .nav-item .nav-link.show,
.navbar-light .navbar-nav .nav-item .nav-link:hover,
.navbar-light .navbar-nav .nav-item .nav-link:focus {
  color: #ff2c57;
}
.navbar-light .navbar-nav .active .nav-link,
.navbar-light .navbar-nav.show .nav-link {
  color: #ff2c57;
}
.navbar-light.header-sticky.is-sticky .navbar-brand {
  color: #000;
}
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link {
  color: #000;
  padding-right: 0;
  padding-left: 0;
}
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link.active,
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link.show,
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link:hover,
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link:focus {
  color: #ff2c57;
}
.navbar-light.header-sticky.is-sticky .navbar-nav .active .nav-link,
.navbar-light.header-sticky.is-sticky .navbar-nav .show .nav-link {
  color: #ff2c57;
}
/* ============================== Main Banner CSS ============================== */
.main-banner {
  min-height: 500px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.main-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 255, 163, 0.23) 0,
    rgba(196, 196, 196, 0) 100%
  );
  opacity: 0.77;
}
.pricing-banner {
 
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.pricing-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(254, 255, 255, 0.23) 0,
    rgba(196, 196, 196, 0) 100%
  );
  opacity: 0.77;
}



.dashboard-banner {
  min-height: 100vh;
  min-width: 100vw;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.dashboard-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 255, 163, 0.23) 0,
    rgba(196, 196, 196, 0) 100%
  );
  opacity: 0.77;
}

.item-bg-one {
  background-image: url("../images/main-bg-1.jpg");
}
.item-bg-two {
  background-image: url("../images/main-bg-2.jpg");
}
.item-bg-three {
  background-image: url("../images/main-bg-3.jpg");
}
.main-banner div canvas {
  position: absolute;
  left: 0;
  top: 0;
}
.main-banner-text {
  text-align: center;
}
.main-banner-text h4 {
  color: rgb(0, 0, 0);
  font-weight: 200;
  font-size: 24px;
  letter-spacing: 1px;
}
.main-banner-text h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: #1a4ab9;
  text-transform: capitalize;
  min-height: 180px;
}

.main-banner-text h1 span {
  color: #ff2c57;
  font-weight: 600;
}
.main-banner-text h1 .Typewriter {
  display: inline-block;
  margin-left: 15px;
}
.main-banner-text p {
  color: rgb(0, 0, 0);
  max-width: 730px;
  margin: auto;
  margin-bottom: 30px;
}
.main-banner-text .btn:hover,
.main-banner-text .btn:focus {
  border-color: #fff;
}
.main-banner-text .view-work {
  background: #1a4ab9;
  border-color: #0100c7;
  margin-left: 10px;
}
.main-banner-text .view-work:hover,
.main-banner-text .view-work:focus {
  border-color: #ff2c57;
  background: #ff2c57;
  color: #fff;
}
.home-slides .owl-controls {
  margin-top: 0;
}
/* ============================== Video Banner CSS ============================== */
.video-background {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -100;
}
/* ============================== Creative Banner CSS ============================== */
.creative-banner-one {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-width: 10rem 100vw 0 0;
  border-color: transparent #fff #fff transparent;
  border-style: solid;
}
.creative-banner-two {
  position: relative;
  top: 100%;
  width: 100%;
  height: 0;
}
.creative-banner-two::before {
  content: "";
  border-right: 50vw solid #fff;
  border-top: 160px solid transparent;
  bottom: 0;
  right: 0;
  position: absolute;
  height: 0;
  width: 0;
}
.creative-banner-two::after {
  content: "";
  position: absolute;
  border-left: 50vw solid #fff;
  border-top: 160px solid transparent;
  bottom: 0;
  height: 0;
  left: 0;
  width: 0;
}
.creative-banner-three {
  position: relative;
  top: 100%;
  width: 100%;
  height: 0;
}
.creative-banner-three::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  /* background-image: url('../images/shape.png'); */
  height: 150px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* ============================== Particles Banner CSS ============================== */
#particles-js-two {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
/* ============================== Welcome Area CSS ============================== */
.single-box {
  text-align: center;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  padding: 30px 20px;
  position: relative;
  overflow: hidden;
  transition: 0.4s;
}
.single-box:hover {
  box-shadow: unset;
  transform: translateX(5px);
}
.single-box .icon {
  color: #ff2c57;
  font-size: 45px;
}
.single-box h3 {
  margin-top: 25px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
}
.single-box a {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 45px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
  border-radius: 50% 0 0 0;
  opacity: 0;
  visibility: hidden;
}
.single-box:hover a {
  background: #ff2c57;
  color: #fff;
  opacity: 1;
  visibility: visible;
  right: 0;
  bottom: 0;
}
/* ============================== About Area CSS ============================== */
.about-area {
  background-image: url("../images/patt.png");
  background-position: center center;
}
.about-video {
  position: relative;
}
.about-video .video-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: -26px;
}
.about-video .video-btn a {
  color: #fff;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 72px;
  background: #ff2c57;
  position: relative;
  font-size: 30px;
  border-radius: 50%;
}
.about-video .video-btn a::before,
.about-video .video-btn a::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  transition: all 200ms;
  animation: pulse-border 1500ms ease-out infinite;
  border: 10px solid #fff;
}
.about-video .video-btn a::after {
  width: 70px;
  height: 70px;
  transition: all 200ms;
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.2);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.2);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    opacity: 0;
  }
}
.about-gf365 {
  margin-top: 12px;
}
.about-gf365 .section-title {
  margin-bottom: 20px;
  padding-left: 0;
}
.about-gf365 .section-title::before,
.about-gf365 .section-title::after {
  display: none;
}
.about-gf365 .section-title h2 {
  font-size: 25px;
}
.about-gf365 ul {
  margin-bottom: 5px;
}
.about-gf365 ul li {
  margin-bottom: 10px;
  font-weight: 300;
  color: #666;
}
.about-gf365 ul li i {
  color: #ff2c57;
  margin-right: 5px;
}
.about-gf365 ul li:last-child {
  margin-bottom: 0;
}
.about-gf365 .pull-left {
  margin-right: 45px;
}
/* ============================== Who We Are Area CSS ============================== */
.who-we-are {
  padding-bottom: 50px;
}
.single-who-we-are {
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
  background: #fff;
  padding: 50px 30px;
  transition: all 0.5s ease-in-out 0s;
}
.single-who-we-are:hover {
  background: #ff2c57;
  color: #fff;
  transform: translateX(5px);
}
.single-who-we-are i {
  font-size: 50px;
  color: #ff2c57;
  transition: 0.4s;
}
.single-who-we-are:hover i {
  color: #fff;
}
.single-who-we-are h4 {
  margin-bottom: 20px;
  margin-top: 25px;
  font-size: 22px;
  font-weight: 500;
}
.single-who-we-are p {
  transition: 0.4s;
}
.single-who-we-are:hover p {
  color: #fff;
}
.single-who-we-are span {
  bottom: 0;
  color: rgba(0, 0, 0, 0.03);
  font-size: 200px;
  line-height: 180px;
  position: absolute;
  right: 0;
  transition: 0.4s;
}
.single-who-we-are:hover span {
  color: rgba(255, 255, 255, 0.2);
}
/* ============================== Strategy Area CSS ============================== */
.strategy-area {
  position: relative;
}
.strategy-area .image {
  background-image: url("../images/about-strategy.jpeg");
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: -20px;
}
.about-strategy {
  padding-left: 25px;
}
.about-strategy .section-title {
  margin-bottom: 20px;
  padding-left: 0;
}
.about-strategy .section-title::before,
.about-strategy .section-title::after {
  display: none;
}
.about-strategy ul {
  margin-bottom: 30px;
}
.about-strategy ul li {
  margin-bottom: 10px;
  font-weight: 300;
  color: #666;
}
.about-strategy ul li:last-child {
  margin-bottom: 0;
}
.about-strategy ul li i {
  color: #ff2c57;
  margin-right: 5px;
}
/* ============================== CTR Area CSS ============================== */
.ctr-area {
  text-align: center;
  background-image: url("../images/ctr-bg.jpg");
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ctr-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.88;
}
.ctr-area .section-title {
  padding-left: 0;
}
.ctr-area .section-title::after,
.ctr-area .section-title::before {
  display: none;
}
.ctr-area .section-title h2 {
  color: #fff;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
}
.ctr-area .section-title p {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  color: #fff;
}
.ctr-area .view-work {
  background: transparent;
  border-color: #fff;
  margin-left: 10px;
}
.ctr-area .view-work:hover,
.ctr-area .view-work:focus {
  border-color: #ff2c57;
  background: #ff2c57;
  color: #fff;
}
.ctr-area .btn:hover,
.ctr-area .btn:focus {
  border-color: #fff;
}
/* ============================== Skill Area CSS ============================== */
.about-skill .section-title {
  padding-left: 0;
}
.about-skill .section-title::before,
.about-skill .section-title::after {
  display: none;
}
.about-skill .progress-title {
  font-size: 17px;
  margin-bottom: 13px;
}
.about-skill .progress {
  height: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible;
}
.about-skill .progress .progress-bar {
  box-shadow: none;
  position: relative;
  animation: animate-positive 2s;
  background: #ff2c57;
  border-radius: 5px;
}
.about-skill .progress .progress-value {
  font-size: 15px;
  font-weight: 300;
  color: #222;
  position: absolute;
  top: -29px;
  right: 0;
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.skill-video {
  position: relative;
}
.skill-video .video-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: -26px;
}
.skill-video .video-btn a {
  color: #fff;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 72px;
  background: #ff2c57;
  position: relative;
  font-size: 30px;
  border-radius: 50%;
}
.skill-video .video-btn a::before,
.skill-video .video-btn a::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  animation: pulse-border 1500ms ease-out infinite;
  border: 10px solid #fff;
}
.skill-video .video-btn a::after {
  width: 70px;
  height: 70px;
  transition: all 200ms;
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}
/* ============================== Fun Facts Area CSS ============================== */
.funFacts-area {
  background-image: url("../images/funfact-bg.jpg");
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.funFacts-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.77;
}
.funFact {
  position: relative;
  padding-left: 65px;
}
.funFact i {
  font-size: 50px;
  color: #ff2c57;
  position: absolute;
  left: 0;
  top: 2px;
}
.funFact .fa.fa-users {
  left: -10px;
}
.funFact h2 {
  color: #fff;
  margin-bottom: 5px;
  font-size: 45px;
  font-weight: 600;
}
.funFact p {
  color: #fff;
  font-size: 18px;
}
/* ============================== Team Area CSS ============================== */
.our-team {
  text-align: center;
  position: relative;
}
.our-team .team-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 92%;
  background: #fff;
  margin-left: 15px;
  padding-top: 20px;
  z-index: 1;
  transition: 0.4s;
}
.our-team .team-content .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
  transition: 0.4s;
}
.our-team .team-content .post {
  color: #666;
  transition: 0.4s;
}
.our-team:hover .team-content .title {
  color: #fff;
}
.our-team:hover .team-content .post {
  color: #fff;
}
.our-team:hover .team-content {
  bottom: 34%;
  background: transparent;
}
.our-team .social {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 20%,
    rgba(0, 0, 0, 0.3) 80%
  );
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
}
.our-team .social ul {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -50px;
  transform: translateY(-35px);
  transition: 0.4s;
}
.our-team .social ul li {
  display: inline-block;
  margin: 0 4px;
}
.our-team .social ul li a {
  width: 35px;
  height: 35px;
  line-height: 32px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}
.our-team .social ul li a:hover {
  border-color: #ff2c57;
  background: #ff2c57;
}
.our-team:hover .social {
  opacity: 1;
  visibility: visible;
}
.our-team:hover .social ul {
  transform: translateY(0);
}
/* ============================== How We Work CSS ============================== */
.how-work {
  background-image: url("../images/dott.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
}
.single-how-work {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: #fff;
  padding: 35px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out 0s;
}
.single-how-work:hover {
  border-bottom: 3px solid #1a4ab9;
  transform: scale(1.1, 1.1);
}
.single-how-work h3 {
  color: #ff2c57;
  font-size: 45px;
  font-weight: 700;
}
.single-how-work h4 {
  margin-top: 25px;
  margin-bottom: 18px;
  font-size: 22px;
}
/* ============================== Services Area CSS ============================== */
.services-area {
  padding-bottom: 50px;
}
.services-area .single-box {
  margin-bottom: 30px;
}
.single-services {
  margin-bottom: 30px;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  text-align: center;
  position: relative;
  transition: 0.4s;
}
.single-services:hover {
  box-shadow: unset;
  transform: translateX(5px);
}
.single-services::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  border-left: 2px solid #ff2c57;
  height: 50px;
  border-bottom: 2px solid #ff2c57;
  opacity: 0;
  transition: 0.4s;
}
.single-services:hover::before {
  opacity: 1;
  width: 100%;
  height: 50%;
}
.single-services .services-img {
  position: relative;
}
.single-services .services-img::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.66;
}
.single-services .services-img .icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -30px;
}
.single-services i {
  width: 70px;
  height: 70px;
  line-height: 71px;
  border: 1px solid #fff;
  font-size: 30px;
  color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}
.single-services:hover i {
  background: #fff;
  color: #ff2c57;
}
.single-services .services-content {
  padding: 30px 25px;
  position: relative;
}
.single-services .services-content h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 18px;
}
/* ============================== Why We Are Different CSS ============================== */
.tabset input[type="radio"] {
  display: none;
}
.tabset .tab-panel {
  display: none;
}
.tabset input:first-child:checked ~ .tab-panels .tab-panel:first-child {
  display: block;
}
.tabset input:nth-child(3):checked ~ .tab-panels .tab-panel:nth-child(2) {
  display: block;
}
.tabset input:nth-child(5):checked ~ .tab-panels .tab-panel:nth-child(3) {
  display: block;
}
.tabset input:nth-child(7):checked ~ .tab-panels .tab-panel:nth-child(4) {
  display: block;
}
.tabset input:nth-child(9):checked ~ .tab-panels .tab-panel:nth-child(5) {
  display: block;
}
.tabset input:nth-child(11):checked ~ .tab-panels .tab-panel:nth-child(6) {
  display: block;
}
.tabset label {
  position: relative;
  display: inline-block;
  padding: 15px 32px;
  margin-right: 6px;
  margin-bottom: 0;
  cursor: pointer;
  background: transparent;
  transition: 0.4s;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 18px;
}
.tabset label:hover,
.tabset label:focus,
.tabset label.checked {
  background-color: #ff2c57;
  color: #fff;
}
.tabset input:checked + label {
  border-color: #ff2c57;
  background: #ff2c57;
  color: #fff;
}
.tab-panel {
  margin-top: 35px;
}
.tab-panel .why-we-different-text h4 {
  font-size: 35px;
  font-weight: 200;
  margin-bottom: 20px;
}
.tab-panel .why-we-different-text p {
  max-width: 635px;
  margin-top: -7px;
}
.tab-panel .why-we-different-text ul {
  margin-top: 25px;
  margin-bottom: 30px;
}
.tab-panel .why-we-different-text ul li {
  margin-bottom: 10px;
  font-weight: 300;
  color: #666;
}
.tab-panel .why-we-different-text ul li:last-child {
  margin-bottom: 0;
}
.tab-panel .why-we-different-text ul li i {
  color: #ff2c57;
  margin-right: 5px;
}
/* ============================== Work Area CSS ============================== */
.shorting-menu {
  display: inline-block;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  padding: 18px 20px;
  border-radius: 50px;
}
.shorting-menu .filter {
  border: none;
  background: #f4f4f4;
  padding: 9px 24px;
  text-transform: capitalize;
  font-size: 16px;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 30px;
  color: #000;
  margin: 0 4px;
}
.shorting-menu .filter.active,
.shorting-menu .filter:hover {
  background: #ff2c57;
  color: #fff;
}
.shorting {
  margin-top: 40px;
}
.single-work {
  overflow: hidden;
  position: relative;
  padding: 5px;
}
.single-work::before {
  content: "";
  position: absolute;
  top: 15px;
  width: 95%;
  height: 93%;
  background: #000;
  left: 14px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.single-work:hover::before {
  top: 21px;
  width: 93%;
  height: 91%;
  left: 21px;
  opacity: 0.66;
  visibility: visible;
}
.single-work .work-content {
  position: absolute;
  bottom: 20px;
  width: 86%;
  background: #fff;
  left: 40px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.single-work .work-content h4 {
  font-size: 22px;
  margin-bottom: 10px;
}
.single-work .work-content ul li {
  display: inline-block;
  font-size: 14px;
}
.single-work:hover .work-content {
  bottom: 35px;
  opacity: 1;
  visibility: visible;
}
.single-work .popup-btn {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 45px;
  text-align: center;
  height: 45px;
  line-height: 46px;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #fff;
  transition: 0.4s;
}
.single-work .popup-btn:hover {
  background: #ff2c57;
  border-color: #ff2c57;
}
.single-work:hover .popup-btn {
  top: 36px;
  opacity: 1;
  visibility: visible;
}
/* ============================== Pricing Area CSS ============================== */
.pricingTable {
  padding: 30px 0;
  text-align: center;
  position: relative;
  background-image: url("../images/patt.png");
  background-position: center center;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  transition: 0.4s;
}
.pricingTable:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}
.pricingTable::after {
  content: "";
  width: 70px;
  height: 30px;
  background: #ff2c57;
  margin: 0 auto;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.pricingTable .title {
  padding: 20px 0;
  margin-bottom: 35px;
  background: #ff2c57;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
}
.pricingTable .price-value {
  margin-bottom: 25px;
  color: #ff2c57;
}
.pricingTable .price-value .amount {
  display: inline-block;
  font-size: 44px;
  font-weight: 500;
}
.pricingTable .price-value .month {
  display: block;
  font-size: 15px;
  line-height: 16px;
  text-transform: capitalize;
  color: #666;
  margin-left: 50px;
}
.pricingTable .currency {
  display: inline-block;
  font-size: 25px;
  vertical-align: top;
  margin-top: 6px;
}
.pricingTable .pricing-content {
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  margin-bottom: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.pricingTable .pricing-content li {
  color: #8b8b8b;
  margin-top: 16px;
}
.pricingTable .pricing-content li:first-child {
  margin-top: 0;
}
.pricingTable .pricing-content li i {
  color: #ff2c57;
  margin-right: 4px;
}
/* ============================== FAQ Area CSS ============================== */
.faq-area .image {
  background-image: url("../images/faq-img.jpg");
  position: absolute;
  left: 15px;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.accordion {
  border: none;
  border-radius: 0;
}
.accordion .accordion {
  border: none;
  border-radius: 0;
}
.accordion__item {
  border-top: none;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  margin-bottom: 10px;
}
.accordion__item .accordion__button {
  background-color: #fff;
  color: #000;
  transition: 0.4s;
}
.accordion__item .accordion__button:hover,
.accordion__item .accordion__button:focus {
  background-color: #ff2c57;
  color: #fff;
}
.accordion__item .accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
  border-top: 1px solid #eee;
}
.accordion__item:last-child {
  margin-bottom: 0;
}
/* ============================== Testimonials Area CSS ============================== */
.testimonials-area {
  padding-bottom: 50px;
}
.single-feedback {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background-image: url("../images/patt.png");
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease-in-out 0s;
}
.single-feedback .client-info {
  overflow: hidden;
  margin-bottom: 30px;
}
.single-feedback .client-info .client-pic {
  float: left;
  width: 30%;
}
.single-feedback .client-info .client-pic img {
  border-radius: 50%;
}
.single-feedback .client-info .client-title {
  float: left;
  width: 70%;
  padding-left: 20px;
  margin-top: 17px;
}
.single-feedback .client-info .client-title h4 {
  font-size: 19px;
  font-weight: 500;
  transition: 0.4s;
}
.single-feedback .client-info .client-title h5 {
  font-size: 15px;
  margin-top: 13px;
  color: #666;
  transition: 0.4s;
}
.single-feedback p {
  transition: 0.4s;
}
.single-feedback span {
  font-size: 175px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.03);
  transition: 0.4s;
  line-height: 0;
}
.single-feedback:hover {
  transform: translateX(5px);
  background: #ff2c57;
}
.single-feedback:hover .client-title h4,
.single-feedback:hover .client-title h5 {
  color: #fff;
}
.single-feedback:hover p {
  color: #fff;
}
.single-feedback:hover span {
  color: rgba(255, 255, 255, 0.2);
}
/* ============================== Blog Area CSS ============================== */
.single-blog-item {
  position: relative;
  padding: 30px;
  border: 1px solid #eee;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}
.single-blog-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: 0.4s;
}
.single-blog-item span,
.single-blog-item p,
.single-blog-item h4 {
  position: relative;
  transition: 0.4s;
}
.single-blog-item h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 500;
}
.single-blog-item h4 a:hover {
  color: #ff2c57 !important;
}
.single-blog-item .link-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
  border-radius: 50% 0 0 0;
}
.single-blog-item:hover::before {
  background: #000;
  opacity: 0.66;
}
.single-blog-item:hover span,
.single-blog-item:hover p,
.single-blog-item:hover h4 a {
  color: #fff;
}
.single-blog-item:hover .link-btn {
  background: #ff2c57;
  color: #fff;
}
/* ============================== Partner Area CSS ============================== */
.partner-area {
  text-align: left;
}
.partner-area .owl-carousel .owl-item img {
  width: 140px;
}
/* ============================== Subscribe Area CSS ============================== */
.subscribe-area {
  background-image: url("../images/pattern.png");
  text-align: center;
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  background-attachment: fixed;
}
.newsletter {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: #fff;
  padding: 35px;
  border-radius: 5px;
}
.newsletter h4 {
  text-transform: capitalize;
  margin-bottom: 30px;
  font-size: 30px;
}
.newsletter form {
  position: relative;
}
.newsletter form .form-control {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border: none;
  height: 75px;
  border-radius: 50px;
  padding-left: 20px;
  font-size: 17px;
}
.newsletter form .btn {
  position: absolute;
  right: 7px;
  top: 8px;
  height: 60px;
  width: 175px;
}
.newsletter form .validation-danger {
  margin-top: 20px;
  color: red;
}
.newsletter form .validation-success {
  margin-top: 20px;
}
/* ============================== Contact Area CSS ============================== */
#map {
  width: 100%;
  height: 400px !important;
}
.contact-info {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: #fff;
  padding: 30px;
  border-top: 3px solid #ff2c57;
  border-bottom: 3px solid #ff2c57;
}
.contact-info ul li {
  position: relative;
  padding-left: 60px;
  font-size: 16px;
  margin-bottom: 34px;
  color: #151515;
}
.contact-info ul li:last-child {
  margin-bottom: 0;
}
.contact-info ul li i {
  position: absolute;
  left: 0;
  top: -11px;
  color: #ff2c57;
  font-size: 19px;
  background: #f6f6f6;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
}
.contact-info .follow-us {
  text-align: right;
  margin-top: 40px;
}
.contact-info .follow-us li {
  padding-left: 0;
  display: inline-block;
  margin-bottom: 0;
}
.contact-info .follow-us li:first-child {
  font-size: 18px;
  font-weight: 400;
  margin-right: 5px;
}
.contact-info .follow-us li a {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border: 1px solid #000;
  border-radius: 50%;
}
.contact-info .follow-us li a:hover,
.contact-info .follow-us li a:focus {
  background: #ff2c57;
  color: #fff;
  border-color: #ff2c57;
}
.contact-form {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  padding: 40px 30px;
  border-top: 3px solid #ff2c57;
  border-bottom: 3px solid #ff2c57;
  background: #fff;
}
.contact-form h4 {
  color: #ff2c57;
  margin-bottom: 25px;
  font-size: 22px;
}
.contact-form .list-unstyled li {
  color: red;
}
.contact-form .form-control {
  border-radius: 30px;
}
.contact-form .btn {
  margin-top: 20px;
}
.contact-form #message {
  height: auto;
  padding: 15px;
}
/* ============================== Page Title CSS ============================== */
.page-title {
  height: 550px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;
}
.page-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
}
.page-title h1 {
  margin-bottom: 35px;
  margin-top: 65px;
  color: #fff;
  font-size: 45px;
  font-weight: 600;
}
.page-title ul li {
  display: inline-block;
  margin: 0 3px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
.page-title ul li.active {
  color: #ccc;
}
.page-title ul li a {
  color: #fff;
}
.page-title ul li a:hover {
  color: #ff2c57;
}
/* ============================== Blog Details Area CSS ============================== */
.blog-details-area {
  /* background-image: url("../images/pattern.png"); */
  background-attachment: fixed;
}
.blog-details-area .title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 27px;
}
.blog-details {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  background: #fff;
}
.blog-details .article-img {
  position: relative;
}
.blog-details .article-img .date {
  position: absolute;
  bottom: 15px;
  width: 100px;
  left: 15px;
  height: 100px;
  text-align: center;
  font-size: 25px;
  font-style: italic;
  background: #ff2c57;
  color: #fff;
  border-radius: 5px;
  padding-top: 19px;
  line-height: 32px;
}
.blog-details .article-text {
  padding: 30px;
}
.blog-details .article-text ul li {
  display: inline-block;
  margin-right: 4px;
}
.blog-details .article-text .category a {
  background: #ff2c57;
  color: #fff;
  padding: 3px 15px;
  display: inline-block;
  border-radius: 3px;
  font-weight: 400;
}
.blog-details .article-text p {
  margin-bottom: 20px;
}
.blog-details .article-text .blockquote {
  margin-bottom: 25px;
  margin-top: 25px;
  background: #f4f4f4;
  padding: 30px;
  font-size: 17px;
  border-left: 4px solid #ff2c57;
}
.blog-details .article-text .blockquote p {
  color: #000;
}
.blog-details .article-text .author-share {
  margin-top: 40px;
}
.blog-details .article-text .author-share .social-share {
  text-align: right;
  margin-top: 28px;
}
.blog-details .article-text .author-share .social-share a {
  background: #ff2c57;
  color: #fff;
  width: 35px;
  display: inline-block;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
}
.blog-details .article-text .article-author {
  overflow: hidden;
}
.blog-details .article-text .article-author .author-img {
  float: left;
  width: 27%;
}
.blog-details .article-text .article-author .author-img img {
  border-radius: 50%;
  max-width: 100%;
  width: auto !important;
}
.blog-details .article-text .article-author .author-title {
  float: left;
  width: 73%;
  padding-left: 25px;
  margin-top: 17px;
}
.blog-details .article-text .article-author .author-title h4 {
  font-size: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.blog-details .article-text .article-author .author-title span {
  color: #666;
  font-size: 15px;
}
.leave-Link-reply {
  margin-top: 35px;
}
.post-controls-buttons {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 70px;
  padding-right: 70px;
}
.post-controls-buttons div {
  display: inline-block;
}
.post-controls-buttons div a {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  padding: 10px 20px;
  border-radius: 5px;
  background: #fff;
  color: #ff2c57;
}
.post-controls-buttons div a:hover {
  background: #ff2c57;
  color: #fff;
}
.post-controls-buttons .controls-right {
  float: right;
}
.post-comments .single-comment {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  padding: 25px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  background: #fff;
}
.post-comments .single-comment.left-m {
  margin-left: 30px;
}
.post-comments .single-comment .comment-img {
  float: left;
  width: 13%;
}
.post-comments .single-comment .comment-img img {
  border-radius: 50%;
}
.post-comments .single-comment .comment-content {
  float: left;
  width: 87%;
  padding-left: 25px;
}
.post-comments .single-comment .comment-content h4 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.post-comments .single-comment .comment-content p {
  margin-bottom: 15px;
}
.post-comments .single-comment .comment-content a {
  position: absolute;
  right: 25px;
  bottom: 25px;
  color: #ff2c57;
  text-transform: uppercase;
  font-size: 17px;
}
.leave-a-reply {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  padding: 25px;
  background: #fff;
  margin-top: 50px;
}
.leave-a-reply .btn {
  margin-top: 0px;
}
/* ============================== Footer Area CSS ============================== */
.footer-area {
  text-align: center;
  padding: 50px 0;
  background: #222;
}
.footer-area h4 {
  line-height: 22px;
  margin-bottom: 30px;
}
.footer-area h4 a {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 35px;
}
.footer-area h4 span {
  color: #ff2c57;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer-area ul li {
  display: inline-block;
  margin: 0 4px;
}
.footer-area ul li a {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 34px;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
}
.footer-area ul li a:hover,
.footer-area ul li a:focus {
  background: #ff2c57;
  color: #fff;
  border-color: #ff2c57;
}
.footer-area p {
  line-height: 1;
  margin-top: 0px;
  color: #fff;
}
/* ============================== Go Top CSS ============================== */
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 20px;
  font-size: 25px;
  color: #fff;
  background-color: #000;
  z-index: 1;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 39px;
  border-radius: 50%;
  transition: 0.4s;
}
.go-top:hover {
  color: #fff;
  background: #ff2c57;
}
:invalid {
  box-shadow: none;
}
:-moz-submit-invalid {
  box-shadow: none;
}
:-moz-ui-invalid {
  box-shadow: none;
}

/* New Style */

.page-wrapper .page-main {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.checkout_btn {
  width: 100%;
  display: block;
  background: 0 0;
  border: 1px solid #00b868;
  height: 48px;
  color: #00b868;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  cursor: pointer;
}
.breadcrumbs .myContainer {
  position: relative;
}
.gree_btn {
  background: #00b868;
  color: #fff;
}
.columns .column.main {
  padding: 0;
  display: block;
  order: unset;
}
.buttonbox .mobile-documnet-upload,
.calender-dropdown,
.carousel-control-next-icon,
.carousel-control-prev-icon,
.categoery_l1_slide .owl-next span,
.categoery_l1_slide .owl-prev span,
.categoery_slide .owl-next span,
.categoery_slide .owl-prev span,
.dark,
.owl-dots,
.sections.nav-sections,
a.mobile-button {
  display: none;
}
.mcmaster_Carr h1 {
  line-height: 29px;
  display: flex;
  justify-content: center;
}
.mcmaster_Carr h1 img {
  margin-right: 10px;
}
.banner {
  width: 100%;
  background: #fff;
  font-size: 0;
  position: relative;
}
.banner:after {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 255, 163, 0.23) 0,
    rgba(196, 196, 196, 0) 100%
  );
  height: 100%;
  content: "";
  width: 32%;
  z-index: 0;
}
.banner-content {
  display: flex;
  align-items: center;
}
.bannerimage {
  width: 50%;
  position: relative;
  z-index: 1;
}
.bannertext {
  width: 50%;
  padding-right: 40px;
}
.bannertext h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: #1a4ab9;
  text-transform: capitalize;
  font-family: Circular Std;
}
.bannertext label {
  position: relative;
  margin-top: 46px;
  width: 100%;
  max-width: 494px;
}
.bannertext input {
  width: 100%;
  padding: 12px 43px 12px 16px;
  font-size: 14px;
  border: 1px solid #30bdff;
  color: #000;
  font-family: "Circular Std";
  line-height: 21px;
  background-color: #fff;
  caret-color: #30bdff;
}
.bannertext input::placeholder {
  color: rgba(26, 74, 185, 0.6);
}
.bannertext button {
  position: absolute;
  right: 4px;
  border: 0;
  background: rgba(151, 222, 255, 0.6);
  padding: 8px 13px;
  top: 4px;
}
.bannertext button:focus,
.form-area button.submit-button:focus,
.main-menu .leftmenu-searchbar .search-bar button:focus,
.minisearch button:focus,
.search-bar button:focus {
  outline: 0;
  border: none;
}
.bannerimage img {
  max-width: 100%;
  margin-top: 30px;
}
.banner input {
  border: 1px solid #1a4ab9;
  font-family: "Circular Std Book";
}
.banner .search-icon {
  background-position: -274px -192px;
}
.searchtypetext {
  word-break: break-all;
}
.whoweare + .brands {
  padding-top: 0;
}
.brands {
  width: 100%;
  padding: 90px 0 40px;
}
.brandsimage {
  padding-bottom: 60px;
}
.brandsimage ul {
  list-style-type: none;
  margin: 52px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brandsimage li {
  margin-bottom: 0;
}
.viewall {
  margin: 30px auto 0;
  text-align: center;
}
.viewall a {
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
  padding: 15px 40px;
  border: 1px solid #00b868;
  font-family: "Circular Std Book";
}
.viewall a:hover {
  text-decoration: none;
  color: #00b868;
}
.catalogue-category {
  background-color: rgba(27, 33, 49, 0.05);
  padding-top: 50px;
  width: 100%;
}
.categoery_slide {
  margin-top: 70px;
}
.catalogue-category ul {
  list-style-type: none;
  padding-bottom: 35px;
  margin: 0;
}
.catalogue-category li {
  position: relative;
  margin-bottom: 15px;
  font-size: 14px;
  max-width: 227px;
  font-family: "Circular Std Book";
  line-height: 19px;
}
.catalogue-category li a {
  color: rgba(27, 33, 49, 0.6);
  text-decoration: none;
  padding: 8px 6px;
  position: relative;
  display: flex;
  align-items: center;
}
.catalogue-category li a .icon {
  background-color: rgba(27, 33, 49, 0.05);
  width: 24px;
  height: 24px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
}
.catalogue-category li:hover {
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(26, 74, 185, 0.1);
}
.categoery_slide button:focus {
  outline: 0;
}
.categoery_slide.owl-theme .owl-nav {
  position: relative;
  text-align: center;
}
.catalogue-category .categoery_slide .owl-prev,
.categoryListItems .categoery_l1_slide .owl-prev,
.cms-l1 .catalogue-category .categoery_slide .owl-prev {
  width: 30px;
  height: 30px;
  background: url(https://cdn.raptorsupplies.com/pub/static/frontend/Raptor/Desktop/en_US/images/spriteicon.png)
    no-repeat !important;
  background-position: -364px -102px !important;
  box-shadow: none;
}
.catalogue-category .categoery_slide .owl-next,
.categoryListItems .categoery_l1_slide .owl-next,
.cms-l1 .catalogue-category .categoery_slide .owl-next {
  width: 30px;
  height: 30px;
  background: url(https://cdn.raptorsupplies.com/pub/static/frontend/Raptor/Desktop/en_US/images/spriteicon.png)
    no-repeat !important;
  background-position: -410px -102px !important;
  box-shadow: none;
}
button.owl-prev:focus {
  background-color: rgba(151, 222, 255, 0.6) !important;
  background-position: -357px -187px !important;
}
button.owl-next:focus {
  background-color: rgba(151, 222, 255, 0.6) !important;
  background-position: -404px -187px !important;
}
.catalogue-category li:hover .abrasives {
  background-position: -630px -189px;
}
.catalogue-category li:hover .adhesives {
  background-position: -675px -189px;
}
.catalogue-category li:hover .appliances-batteries {
  background-position: -720px -189px;
}
.catalogue-category li:hover .cleaning {
  background-position: -765px -189px;
}
.catalogue-category li:hover .electrical {
  background-position: -810px -189px;
}
.catalogue-category li:hover .fasteners {
  background-position: -855px -189px;
}
.catalogue-category li:hover .furniture-services {
  background-position: -900px -189px;
}
.catalogue-category li:hover .hand-tools {
  background-position: -945px -189px;
}
.catalogue-category li:hover .hardware {
  background-position: -990px -189px;
}
.catalogue-category li:hover .hvac {
  background-position: -1080px -189px;
}
.catalogue-category li:hover .hydraulics {
  background-position: -1125px -189px;
}
.catalogue-category li:hover .lab-supplies {
  background-position: -1170px -189px;
}
.catalogue-category li:hover .lighting {
  background-position: -540px -234px;
}
.catalogue-category li:hover .lubrication {
  background-position: -630px -234px;
}
.catalogue-category li:hover .machining {
  background-position: -810px -234px;
}
.catalogue-category li:hover .material-handling {
  background-position: -990px -234px;
}
.catalogue-category li:hover .motors {
  background-position: -1035px -189px;
}
.catalogue-category li:hover .office-supplies {
  background-position: -1215px -189px;
}
.catalogue-category li:hover .outdoor-equipment {
  background-position: -1260px -189px;
}
.catalogue-category li:hover .paint-supplies {
  background-position: -273px -234px;
}
.catalogue-category li:hover .plumbing {
  background-position: -495px -234px;
}
.catalogue-category li:hover .pneumatics {
  background-position: -675px -234px;
}
.catalogue-category li:hover .power-tool {
  background-position: -855px -234px;
}
.catalogue-category li:hover .power-transmission {
  background-position: -1035px -234px;
}
.catalogue-category li:hover .pumps {
  background-position: -1305px -189px;
}
.catalogue-category li:hover .raw-materials {
  background-position: -45px -234px;
}
.catalogue-category li:hover .reference-supplies {
  background-position: -225px -234px;
}
.catalogue-category li:hover .safety {
  background-position: -360px -234px;
}
.catalogue-category li:hover .security {
  background-position: -450px -234px;
}
.catalogue-category li:hover .test-instruments {
  background-position: -720px -234px;
}
.catalogue-category li:hover .vehicle-maintenance {
  background-position: -900px -234px;
}
.catalogue-category li:hover .welding {
  background-position: -1080px -234px;
}
.catalogue-category li a .left-icon {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 16px;
  height: 24px;
  width: 24px;
}
.sourceoutside {
  width: 100%;
  background: #1a4ab9;
  padding: 56px 0;
  color: #fff;
  position: relative;
}
.sourceoutside img {
  padding-top: 50px;
}
.outsideleft {
  padding: 50px 0;
  float: left;
}
.sourceoutside h2 {
  color: #fff;
}
.sourceoutside .outsideleft h3 {
  font-weight: 700;
  font-size: 24px;
}
.sourceoutside .outsideleft p {
  font-size: 16px;
  line-height: 22px;
  padding: 30px 0;
  font-family: "Circular Std Book";
  opacity: 0.8;
}
.sourceoutside .outsideleft h4 {
  font-size: 18px;
  font-weight: 700;
}
.buttonbox {
  padding-top: 40px;
}
.buttonbox .submit_rfq {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 32px;
  display: block;
  border: 1px solid #fff;
  background: rgba(151, 222, 255, 0.3);
  float: left;
  margin-right: 16px;
  cursor: pointer;
  font-family: "Circular Std Book";
}
a.submit_rfq.sweepTop:focus {
  color: #1a4ab9;
  background-color: #fff;
}
.buttonbox .submit_rfq:active::before {
  background: rgba(151, 222, 255, 0.3);
}
.buttonbox .uploadimg {
  font-family: "Circular Std Book";
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  display: block;
  border: 1px solid #00b868;
  background: #00b868;
  float: left;
  cursor: pointer;
}
.buttonbox .uploadimg:focus {
  outline: 0;
}
.fmabout_head .myContainer {
  max-width: 1200px;
}
.about-us-blurbs {
  display: flex;
  margin-top: 40px;
  align-items: flex-start;
}
.blurbs {
  background-color: #fff;
  border: 1px solid rgba(151, 222, 255, 0.6);
  box-shadow: 0 8px 24px rgb(26 74 185 / 5%);
  width: 100%;
  padding: 15px;
  margin-right: 15px;
  height: 135px;
}
.blurbs:hover {
  box-shadow: 0 8px 24px rgb(26 74 185 / 15%);
  border-bottom: 3px solid #1a4ab9;
}
.blurbs:last-child {
  margin-right: 0;
}
.blurbs h2 {
  color: #1b2131;
  font-family: Circular Std;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.blurbs h2 img {
  margin-right: 10px;
}
.blurbs ul {
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 0;
  width: 49%;
  vertical-align: top;
  display: inline-block;
}
.blurbs li,
.blurbs p {
  color: rgba(27, 33, 49, 0.6) !important;
  font-family: "Circular Std Book" !important;
  font-size: 14px !important;
  text-align: left !important;
  line-height: 19px;
}
.blurbs p {
  margin-top: 10px;
  margin-bottom: 0;
}
.blurbs li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 12px;
}
.blurbs li:after {
  content: "";
  top: 5px;
  left: 0;
  background-color: #1a4ab9;
  height: 7px;
  width: 7px;
  position: absolute;
  border-radius: 50%;
}
.blurbs li:last-child {
  margin-bottom: 0;
}
.whoweare {
  padding: 48px 0 68px;
  width: 100%;
  background: rgba(27, 33, 49, 0.05);
}
.whoweare .whoweareleft {
  padding: 100px 0 60px;
  font-weight: 400;
  font-size: 16px;
  color: rgba(27, 33, 49, 0.6);
  padding-right: 120px;
}
.whoweareleft p {
  color: rgba(27, 33, 49, 0.6);
  font-size: 16px;
  line-height: 22px;
  font-family: "Circular Std Book";
}
.whoweareleft p.secondpara {
  font-size: 18px;
  color: #1b2131;
  margin-top: 20px;
}
.allcardbox {
  width: 48%;
}
.allcard:first-child {
  margin-bottom: 70px;
  margin-top: 65px;
}
.cardbox {
  position: relative;
  transition: all 4s ease-out;
}
.card1 {
  background: #fff;
  width: 136px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 30px;
}
.contact-index-index .card1 {
  border: 1px solid rgba(26, 74, 185, 0.1);
}
.card2 {
  background: #fff;
  width: 174px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 10px;
  border: 1px solid rgba(26, 74, 185, 0.1);
  box-sizing: border-box;
  box-shadow: 0 4px 24px rgba(26, 74, 185, 0.1);
}
.card3 {
  width: 100%;
  background: #fff;
  height: 102px;
  position: relative;
  border: 1px solid rgba(26, 74, 185, 0.1);
  box-sizing: border-box;
  box-shadow: 0 4px 24px rgba(26, 74, 185, 0.1);
  padding: 12px 0 19px 20px;
}
.animated-cards .cardbox .card2 {
  transform: rotate(15deg);
  transition: all 0.5s ease-out;
}
.animated-cards .cardbox .card1 {
  transform: rotate(33.19deg);
  transition: all 0.5s ease-out;
  border: 1px solid rgba(26, 74, 185, 0.1);
  box-shadow: 0 4px 24px rgb(26 74 185 / 10%);
}
.card3 h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #1a4ab9;
  font-family: "Circular Std Book";
}
.card3 p {
  font-weight: 700;
  font-size: 16px;
  color: rgba(27, 33, 49, 0.6);
  margin: 0;
  padding: 0;
  font-family: "Circular Std";
}
.allcard {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.where-we-deliver {
  padding: 48px 0 0 0;
  width: 100%;
}
.whoweare.white-bg {
  float: left;
  clear: both;
}
.where-we-deliver img {
  margin: 56px auto 0;
  display: block;
}
.pulse,
.pulse1,
.pulse2,
.pulse3 {
  position: absolute;
  top: 62%;
  left: 51%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background: #00b868;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 0 8px transparent, 0 0 0 9px transparent;
}
.pulse1 {
  top: 47%;
  left: 63%;
}
.pulse2 {
  top: 28%;
  left: 75%;
}
.pulse3 {
  top: 32%;
  left: 50%;
}
.pulse1:after,
.pulse1:before,
.pulse2:after,
.pulse2:before,
.pulse3:after,
.pulse3:before,
.pulse:after,
.pulse:before {
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(0, 184, 104, 0.4);
  border-radius: 50%;
  animation: animate 2s linear infinite;
}
.pulse1:after,
.pulse2:after,
.pulse3:after,
.pulse:after {
  animation-delay: 1s;
}
@keyframes animate {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(0.7);
  }
}
.blogs {
  padding: 64px 0;
  width: 100%;
}
.blogs .main-div {
  background: rgba(27, 33, 49, 0.05);
  padding: 0 0 10px;
  margin-top: 40px;
  transition: 0.5s all;
}
.blogs .main-div img {
  width: 100%;
  transform: scale(1.2) translate(-20px, 0);
  transition: 0.9s ease-in-out;
}
.blogs .main-div h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  padding: 20px 25px 0;
  min-height: 70px;
}
.blogs .main-div h4 a {
  color: #1b2131;
  text-decoration: none;
}
.blogs .main-div p {
  font-weight: 700;
  font-size: 14px;
  color: rgba(27, 33, 49, 0.6);
  padding: 0 25px;
}
.blogs button {
  padding: 10px 30px;
  border: 1px solid #00b868;
  margin: 40px auto 0;
  display: block;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}
.blogs button a {
  color: #00b868;
  text-decoration: none;
}
.main-div:hover {
  background-color: #fff;
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
}
.blogs .col-sm-6 {
  padding-right: 12px;
  padding-left: 12px;
}
.light-grey {
  background-color: rgba(27, 33, 49, 0.05);
}
.field-error,
div.mage-error {
  height: 0;
  margin-top: 0 !important;
  text-align: right;
  color: #dc3737;
  font-size: 14px;
}
.our-catalouge {
  padding: 50px 0;
  background-color: #f5f7fc;
}
.grey-previous {
  height: 20px;
  width: 20px;
  background-position: -365px -286px;
  position: absolute;
  bottom: -33px;
}
.grey-next {
  height: 20px;
  width: 20px;
  background-position: -408px -286px;
  position: absolute;
  bottom: -33px;
  left: -180px;
}
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.vert .active.carousel-item-right,
.vert .carousel-item-next {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.vert .active.carousel-item-left,
.vert .carousel-item-prev {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
.contact-us {
  padding: 25px 0;
}
.grey-bg {
  background: rgba(27, 33, 49, 0.05);
}
.contact-us p {
  color: rgba(27, 33, 49, 0.6);
  font-size: 16px;
  text-align: center;
  font-family: "Circular Std Book";
  line-height: 22px;
}
.contact-us p.info {
  margin-top: 58px;
  margin-bottom: 64px;
}
.contact-us .contact-details {
  display: flex;
  width: 100%;
}
.contact-us .contact-details .box {
  background-color: #fff;
  font-size: 0;
  border: 1px solid rgba(27, 33, 49, 0.05);
  width: 33.33%;
  margin: 0 14px 50px 0;
}
.contact-us .contact-details .box a {
  display: flex;
  align-items: center;
  padding: 20px;
  color: #1b2131;
  text-decoration: none;
}
.contact-us .contact-details .box:last-child {
  margin-right: 0;
}
.contact-us .box .image {
  display: flex;
  width: 20%;
  text-align: center;
  background-color: #f4f4f6;
  height: 60px;
  vertical-align: top;
  justify-content: center;
  align-items: center;
}
.contact-us .box .image img {
  margin: 0 auto;
  max-height: 21px;
  max-width: 25px;
}
.contact-us .box .contact-info {
  padding-left: 20px;
  width: 80%;
  display: inline-block;
}
.contact-us .box .contact-info p {
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;
  font-family: "Circular Std Book";
  line-height: 19px;
  margin-top: 8px;
}
.contact-us .box .contact-info h3 {
  margin-bottom: 0;
  line-height: 23px;
  font-size: 16px;
  font-family: "Circular Std Book";
  font-weight: 400;
}
.contact-us .box .contact-info h3 a {
  color: #1b2131;
}
.contact-us .box:hover .contact-info h3 {
  color: #1a4ab9;
}
.contact-us .box:hover {
  border: 1px solid rgba(151, 222, 255, 0.3);
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
}
.contact-us .box:hover .image {
  background-color: #e1f5ff;
}
.contact-us p span {
  color: #1b2131;
  display: block;
}
.contact-us p span.bold-font {
  font-weight: 700;
  color: rgba(27, 33, 49, 0.6);
}
.contact-us .box:hover .inbox-mail {
  background-position: -1126px -237px;
}
.contact-us .box:hover .phone {
  background-position: -90px -234px;
}
.contact-us .box:hover .whatsapp {
  background-position: -316px -235px;
}
.white-bg {
  background-color: #fff;
}
.b2b-banner {
  /* background-image: url(../images/b2b.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 142px 0;
}
.b2b-banner h1 {
  font-size: 40px;
  color: #1a4ab9;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 16px;
}
.b2b-banner p {
  color: #1b2131;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  font-family: "Circular Std Book";
}
.b2b-banner p span {
  color: #00b868;
}
.invoice-documentation-cards .cards .blue-right-arrow {
  position: absolute;
  top: 3px;
  left: -26px;
}
.terms-and-services {
  margin-top: 72px;
}
.contact-us h1,
.contact-us h2,
.terms-and-services h2 {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  font-family: "Circular Std";
}
.contact-us h1:after,
.terms-and-services h2:after {
  background-color: #00b868;
  height: 3px;
  width: 40px;
  position: absolute;
  content: "";
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.document-cards,
.terms-and-services .cards {
  display: flex;
  justify-content: space-between;
}
.terms-and-services .box {
  background-color: #fff;
  padding: 40px;
  min-height: 320px;
  width: 32%;
}
.terms-and-services .box:hover {
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
}
.terms-and-services .box:hover .green-rightarrow {
  background-position: -410px -102px;
  height: 12px;
  width: 18px;
}
.invoice-documentation-cards .cards h6,
.terms-and-services .box h6 {
  color: #1a4ab9;
  font-size: 18px;
  margin-top: 25px;
  font-family: "Circular Std";
  font-weight: 700;
}
.terms-and-services .box p {
  font-size: 14px;
  color: rgba(27, 33, 49, 0.6);
  margin-top: 16px;
  font-family: "Circular Std Book";
  line-height: 19px;
  margin-bottom: 19px;
}
.terms-and-services .box a {
  color: #00b868;
  font-size: 14px;
  font-family: "Circular Std";
  text-decoration: none;
  font-weight: 700;
  position: relative;
}
.mrgn {
  margin-top: 82px;
}
.invoice-documentation-cards {
  margin: 50px 0 110px 0;
}
.invoice-documentation-cards .cards {
  border: 1px solid rgba(151, 222, 255, 0.3);
  padding: 50px 100px;
  border-top: 7px solid #30bdff;
  min-height: 285px;
  width: 49%;
}
.invoice-documentation-cards .cards h6 {
  margin-top: 0;
  position: relative;
  font-family: "Circular Std";
}
.invoice-documentation-cards .cards p {
  color: #1b2131;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 16px;
  line-height: 19px;
  font-family: "Circular Std Book";
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.lightblue {
  background-color: rgba(151, 222, 255, 0.3);
  padding: 31px 0;
}
.dashboard {
  padding: 50px 0;
  display: flex;
  max-width: 870px;
  margin: 0 auto;
  width: 100%;
}
.account-activity {
  width: 41%;
}
.account-activity h3 {
  font-size: 18px;
  margin-bottom: 34px;
  color: rgba(27, 33, 49, 0.6);
  position: relative;
  font-weight: 400;
}
input.warning {
  border: 1px solid #dc3737;
}
input.success {
  border: 1px solid #00b868;
}
.d_flex {
  display: flex;
}
.functionality {
  padding: 48px 0 60px;
}
.functionality .group .form-div {
  width: 50%;
  position: relative;
}
.darkblue {
  background-color: #1a4ab9;
}
.talk-quote .lets-talk {
  padding: 0 30px;
  width: 40%;
}
.talk-quote .get-a-quote h1,
.talk-quote .lets-talk h1,
.trade-account h1,
.trade-account h2 {
  color: #fff;
  font-family: "Circular Std";
  position: relative;
  font-weight: 700;
  text-align: center;
  font-size: 34px;
}
h1,
h1.fancyHeading {
  font-size: 34px;
}
.talk-quote .get-a-quote h1 {
  text-align: center;
}
.talk-quote .get-a-quote h1:after,
.talk-quote .lets-talk h1:after,
.trade-account h1:after,
.trade-account h2:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  content: "";
  width: 40px;
  height: 3px;
  background-color: #00b868;
}
.trade-account .select-down {
  top: 52px;
}
.trade-account h1:after,
.trade-account h2:after {
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
}
.trade-account p {
  margin-bottom: 75px;
  display: block;
  margin-top: 74px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Circular Std Book";
}
.trade-account p span {
  display: block;
}
.full-width {
  flex-direction: column;
}
.cms-request-for-quote .talk-quote select {
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  -webkit-appearance: none;
}
.cms-request-for-quote .talk-quote option {
  font-size: 14px;
  color: #1b2131;
  line-height: 19px;
  margin-bottom: 0;
}
.trade-account p a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.talk-quote .get-a-quote h1:after {
  margin: 0 auto;
  right: 0;
  left: 0;
}
.talk-quote .get-a-quote p,
.talk-quote .lets-talk p {
  font-family: "Circular Std";
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 47px;
  line-height: 20px;
}
.talk-quote .get-a-quote p {
  text-align: center;
  font-size: 16px;
  margin-top: 58px;
  margin-bottom: 53px;
  font-family: "Circular Std Book";
  line-height: 22px;
}
.talk-quote .thankyou-right p {
  text-align: left;
  margin-top: 0;
  font-size: 18px;
  line-height: 25px;
}
.talk-quote .get-a-quote p br {
  display: none;
}
.talk-quote .lets-talk p a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 700;
  text-decoration: none;
}
.talk-quote .lets-talk p a:hover {
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.6);
}
.talk-quote .lets-talk h3 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
}
.talk-quote .lets-talk h3 a {
  color: #30bdff;
}
.talk-quote .lets-talk h3:after {
  border-right: 2px solid #30bdff;
  border-top: 2px solid #30bdff;
  height: 8px;
  width: 8px;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 10px;
}
.talk-quote .lets-talk h3:before {
  height: 2px;
  width: 12px;
  background-color: #30bdff;
  content: "";
  position: absolute;
  right: 0;
  top: 13px;
  display: none;
}
.talk-quote .lets-talk h3:hover::before {
  display: block;
  background-color: #fff;
}
.talk-quote .lets-talk h3:hover::after {
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}
.talk-quote .lets-talk h3:hover a {
  color: #fff;
}
.talk-quote .quote-form {
  padding: 0 25px 0 118px;
  width: 60%;
}
.talk-quote .get-a-quote label {
  position: relative;
  display: block;
  font-size: 16px;
  color: #fff;
  font-family: "Circular Std Book";
}
.talk-quote .get-a-quote input,
.talk-quote .get-a-quote select,
.talk-quote .get-a-quote textarea {
  display: block;
  font-size: 16px;
  border: 1px solid #fff;
  background-color: transparent;
  padding: 15px 20px;
  width: 100%;
  color: #fff;
  font-family: "Circular Std Book";
  margin-bottom: 8px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  line-height: 22px;
}
.talk-quote .get-a-quote .again_upload_picture {
  display: none;
}
.cms-source .uploaded-picture {
  padding: 7px 34px 7px 20px;
  height: 40px;
}
.cms-submit-purchase-order .uploaded-picture {
  padding: 13px 34px 13px 20px;
}
.cms-source .uploaded-picture .white-close-icon {
  top: 12px;
}
.uploaded-picture {
  border: 1px solid #fff;
  padding: 13px 20px;
  font-size: 16px;
  position: relative;
  margin-bottom: 8px;
  height: 54px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
}
.uploaded-picture .white-close-icon {
  position: absolute;
  right: 20px;
  top: 19px;
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}
.form-close {
  position: absolute;
  top: 42px;
  right: 42px;
  cursor: pointer;
}
.talk-quote .get-a-quote input,
.talk-quote .get-a-quote select {
  height: 54px;
}
.talk-quote .get-a-quote select {
  z-index: 1;
}
.talk-quote .get-a-quote label.fileBtn {
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 8px;
}
form#image-rfq {
  margin-top: 3em;
}
.talk-quote .get-a-quote option {
  color: #1b2131;
  font-size: 14px;
}
.fileBtn i {
  position: absolute;
  right: 15px;
  opacity: 0.5;
}
.attach-icon {
  background-position: -724px -564px;
  height: 20px;
  width: 20px;
}
.trade-account .talk-quote select {
  height: 54px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
}
.trade-account .talk-quote option {
  color: #1b2131;
  font-family: "Circular Std Book";
  font-size: 14px;
}
.talk-quote .get-a-quote input#document_upload,
.talk-quote .get-a-quote input#fileUpload {
  display: none;
}
label.fileBtn {
  border: 1px solid #fff;
  background-color: transparent;
  padding: 15px 20px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
.talk-quote .get-a-quote button:focus,
.talk-quote .get-a-quote input:focus,
.talk-quote .get-a-quote textarea:focus {
  outline: 0;
}
.talk-quote .get-a-quote textarea {
  padding: 5px 18px;
}
.talk-quote .get-a-quote textarea.message {
  padding: 15px 19px 43px;
}
.talk-quote .get-a-quote .group {
  display: flex;
  font-size: 0;
  width: 100%;
  position: relative;
}
.trade-account a.next-btn {
  padding: 13px 47px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Circular Std Book";
  margin-top: 20px;
  display: none;
}
.trade-account .group .form-div {
  width: 50%;
  position: relative;
}
.trade_account a {
  padding: 13px 47px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Circular Std Book";
  margin-top: 30px;
}
label.terms {
  margin-top: 5px;
}
label.terms a {
  color: #fff;
  font-weight: 700;
  font-family: "Circular Std";
}
.talk-quote .get-a-quote .group label {
  width: 100%;
  margin-left: 27px;
}
.talk-quote .get-a-quote .group label:first-child {
  margin-left: 0;
}
.get-a-quote .group label option {
  color: #1b2131;
}
.talk-quote .get-a-quote button {
  border: none;
  background-color: #00b868;
  font-size: 16px;
  color: #fff;
  padding: 13px 47px;
  margin-top: 20px;
  line-height: 20px;
  cursor: pointer;
  font-family: "Circular Std Book";
  font-weight: 400;
  box-shadow: none;
}
.get-a-quote input::placeholder,
.get-a-quote select,
.get-a-quote textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.get-a-quote input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.get-a-quote textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.talk-quote .get-a-quote input::placeholder,
.talk-quote .get-a-quote textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.get-a-quote .group .country {
  position: relative;
}
.get-a-quote .group ul.country-dropdown {
  position: Absolute;
  top: 77px;
  width: 100%;
  left: 0;
  background-color: #fff;
  list-style-type: none;
  padding: 12px 15px 0 8px;
  box-shadow: 0 8px 24px rgba(26, 74, 185, 0.1);
  height: 294px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: none;
  z-index: 999;
}
.get-a-quote .group .country-dropdown li {
  color: #000;
  font-size: 16px;
  font-family: "Circular Std";
  padding: 8px;
  margin-top: 5px;
}
.get-a-quote .group .country-dropdown li:hover {
  background-color: rgba(27, 33, 49, 0.05);
}
.get-a-quote .group ul.country-dropdown::-webkit-scrollbar {
  width: 4px;
  height: 35px;
}
.get-a-quote .group ul.country-dropdown::-webkit-scrollbar-track {
  margin: 0 auto;
}
.get-a-quote .group ul.country-dropdown::-webkit-scrollbar-thumb {
  background: rgba(27, 33, 49, 0.3);
  border-radius: 5px;
  height: 35px;
}
.select-down {
  position: Absolute;
  top: 52px;
  right: 20px;
  z-index: 1;
}
.white-chevron {
  width: 15px;
  height: 10px;
  background-position: -50px -474px;
}
.cms-submit-purchase-order h1 {
  font-size: 26px;
}
.cms-submit-purchase-order .talk-quote .lets-talk h1:after {
  top: 35px;
}
.get-form {
  max-width: 50%;
  width: 100%;
  margin: 0 auto;
  padding: 0 5px;
}
.get-form .terms input,
.get-form .terms label {
  width: auto;
  display: inline-block;
}
.terms input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  display: none;
}
.trade-details {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 75px;
  max-width: 672px;
  width: 100%;
  margin: 0 auto;
}
.trade-details a {
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  text-decoration: none;
  font-size: 18px;
}
.trade-details a:after {
  border: 3px solid rgba(255, 255, 255, 0.4);
  height: 16px;
  width: 16px;
  content: "";
  position: absolute;
  bottom: 27px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  z-index: 1;
  border-radius: 50%;
  background-color: #1a4ab9;
}
.trade-details:after {
  position: absolute;
  content: "";
  border-top: 2px dashed rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 80%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: block;
  top: -9px;
}
.trade-details a.active {
  color: #fff;
}
.trade-details a.active:after {
  background: #30bdff;
  border: 3px solid rgba(255, 255, 255, 0.6);
}
.trade-account .talk-quote .get-a-quote p a {
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.company-details,
.customer-details,
.invoice-address {
  margin-top: 120px;
}
.company-details h3,
.customer-details h3,
.invoice-address h3 {
  color: #fff;
  text-align: center;
}
.terms {
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.terms .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #fff;
}
.terms .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.terms input:checked ~ .checkmark:after {
  display: block;
}
.terms .company-details a,
.terms .customer-details a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-family: "Circular Std Book";
  display: unset;
  padding: 0;
}
.get-a-quote .company-details .company-policy {
  font-size: 12px;
  text-align: left;
  margin: 0;
  display: block;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-family: "Circular Std Book";
  line-height: 16px;
  margin-top: 2px;
}
.benefits-of-trade-account {
  margin: 50px 0;
}
.benefits-of-trade-account .benefits {
  display: flex;
  margin-top: 92px;
}
.benefits-of-trade-account .benefits .benefits-box {
  width: 25%;
  background-color: #fff;
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
  border-top: 4px solid #30bdff;
  padding: 25px 50px 25px 50px;
  margin-right: 24px;
}
.benefits .benefits-box:last-child {
  margin-right: 0;
}
.benefits-of-trade-account .benefits .benefits-box p {
  font-size: 14px;
  line-height: 25px;
  position: relative;
  margin-bottom: 0;
}
.benefits-of-trade-account .benefits .benefits-box p:after {
  position: Absolute;
  content: "";
  top: 6px;
  left: -20px;
  border-top: 2px solid #30bdff;
  border-right: 2px solid #30bdff;
  height: 7px;
  width: 7px;
  transform: rotate(40deg);
}
.upload-picture {
  max-width: 644px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 70px;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 999;
  transform: translateY(-50%);
  display: none;
}
.upload-picture.talk-quote .get-a-quote .flash-msg p {
  padding: 16px 32px 16px 50px;
}
.upload-picture h2 {
  color: #fff;
}
.upload-picture.talk-quote .get-a-quote label {
  font-family: "Circular Std Book";
  margin-top: 0;
}
.upload-picture input {
  border: 1px solid #fff;
}
.upload-picture.talk-quote .get-a-quote p {
  font-family: "Circular Std Book";
  padding: 30px 0 40px 0;
  margin: 0;
}
.upload-picture .form-div input {
  padding: 12px 16px;
}
.upload-picture button {
  font-size: 16px;
  line-height: 20px;
  margin-top: 32px;
  padding: 13px 47px;
}
.uploaded-files {
  color: #fff;
  font-size: 14px;
}
.listing {
  width: 100%;
  margin-top: 30px;
}
.listing .tab {
  display: flex;
  justify-content: space-evenly;
}
.listing .tab a,
.listing .tab span {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: Center;
  color: rgba(27, 33, 49, 0.7);
  font-size: 18px;
  position: relative;
  font-family: "Circular Std Book";
}
.listing .tab span a {
  color: rgba(27, 33, 49, 0.6);
  text-decoration: none;
}
.listing .tab span.active {
  color: #1a4ab9;
  text-align: center;
  position: relative;
  background-color: rgba(151, 222, 255, 0.6);
}
.listing .tab span.active a {
  color: #1a4ab9;
}
.listing .tab .active:after {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  content: "";
  background: #f4f4f5;
  transform: rotate(45deg);
}
.tab-content {
  margin-top: 34px;
  width: 100%;
  background: #f6f6f6;
}
.tab-content .brand-names {
  width: 100%;
  padding: 20px 0;
}
.tab-content .brand-names ul {
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.tab-content li {
  display: block;
  font-size: 14px;
  color: #1b2131;
  text-decoration: none;
  font-family: "Circular Std Book";
  line-height: 19px;
  word-break: break-word;
  margin: 0 0 15px 0;
  width: 25%;
}
.tab-content a {
  color: #1b2131;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 8px 20px 8px 8px;
}
.tab-content a:after {
  position: absolute;
  content: "";
  /* background-image: url(../images/spriteicon.png); */
  background-position: -145px -196px;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;
}
.tab-content li:hover {
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(26, 74, 185, 0.1);
  color: #1a4ab9;
}
.tab-content a:hover::after {
  display: block;
}
.tab-content li:hover a {
  color: #1a4ab9;
}
.drop-shadow {
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
}
.page-selector {
  display: none;
}
.custom-accordian {
  padding: 56px 0 80px 0;
}
.custom-accordian h1 {
  margin-bottom: 100px;
}
.fancyHeading {
  font-size: 28px;
  color: #1a4ab9;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 42px;
}
.fancyHeading:after {
  content: "";
  position: absolute;
  background-color: #00b868;
  width: 40px;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 3px;
}
.accordian-dropdown {
  background-color: #fff;
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
  padding: 16px 40px;
  margin-bottom: 24px;
}
.custom-accordian h2.accordian-heading {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  line-height: 25px;
  color: #1a4ab9;
  position: relative;
}
.custom-accordian h2.accordian-heading:after {
  display: none;
}
.custom-accordian li,
.custom-accordian p {
  color: rgba(27, 33, 49, 0.6);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 0;
  font-family: "Circular Std Book";
}
.custom-accordian li {
  margin-top: 0;
  position: relative;
}
.custom-accordian li span {
  display: block;
  position: absolute;
  left: -20px;
  top: -2px;
}
.custom-accordian ul {
  list-style-type: none;
  margin: 0 0 0 20px;
  padding: 0;
}
span.alpha {
  color: rgba(27, 33, 49, 0.8);
}
.custom-accordian li ul {
  margin-top: 10px;
}
.down-arrow {
  position: absolute;
  width: 19px;
  height: 19px;
  right: 0;
  top: 15px;
  background-position: -49px -291px;
  transition: 0.5s;
}
.accordian-dropdown .active .down-arrow {
  transform: rotate(180deg);
  background-position: -46px -279px;
}
span.company-number {
  color: #1b2131;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  display: block;
}
span.company-number span {
  color: rgba(27, 33, 49, 0.6);
}
.address p {
  color: #1b2131;
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
  font-family: "Circular Std Book";
}
.address p span {
  color: rgba(27, 33, 49, 0.6);
  font-weight: 400;
}
.custom-accordian .accordian-dropdown h3 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}
.custom-accordian p.cookies {
  margin-top: 8px;
}
.table .static_all {
  font-size: 14px;
}
.table {
  border: 1px solid rgba(27, 33, 49, 0.06);
  width: 100%;
  max-width: 100%;
}
.table .terms_contentnew {
  font-size: 14px;
  color: rgba(27, 33, 49, 0.6);
}
.table > tbody > tr > td {
  border-top: 1px solid rgba(27, 33, 49, 0.06);
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
.table > tbody > tr > td.bor-none {
  border-top: none;
}
.refund-policy h3 {
  margin-bottom: 24px;
}
.refund-policy p {
  font-size: 16px;
  line-height: 22px;
}
.refund-policy p a {
  color: #1a4ab9;
}
.custom-accordian p a {
  color: #1a4ab9;
}
.sub-points li:before {
  position: absolute;
  content: "";
  top: 7px;
  left: -14px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: rgba(27, 33, 49, 0.4);
}
.privacy-policy {
  padding: 20px 0 30px 0;
}
.industries-served h1,
.privacy-policy h1 {
  margin-bottom: 30px;
}
.privacy-policy li,
.privacy-policy p {
  font-size: 14px;
  color: rgba(27, 33, 49, 0.6);
  line-height: 19px;
  margin-bottom: 16px;
  font-weight: 400;
  font-family: "Circular Std Book";
}
.privacy-policy li:last-child {
  margin-bottom: 0;
}
.privacy-policy h3 {
  color: #1a4ab9;
  font-size: 18px;
}
.privacy-policy li {
  position: relative;
  padding: 0 0 0 40px;
}
.privacy-policy li span {
  position: absolute;
  left: 0;
  top: 2px;
}
.privacy-policy ul {
  margin: 25px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.industries-served {
  padding: 48px 0;
}
.industries {
  display: flex;
  margin-bottom: 32px;
  background-color: #fff;
  box-shadow: 0 30px 60px rgb(133 149 158 / 15%);
  padding: 20px;
}
.industries .industries-about {
  padding-left: 30px;
}
.industries-about h3 {
  color: #1a4ab9;
  font-size: 18px;
  line-height: 25px;
}
.industries-about p {
  font-size: 14px;
  color: rgba(27, 33, 49, 0.6);
  line-height: 19px;
  margin-top: 16px;
  font-family: "Circular Std Book";
}
.vendorPage {
  display: flex;
}
.vendorFrombox,
.vendorTextbox {
  width: 50%;
}
.vendorTextbox {
  padding-right: 100px;
}
.vendorTextbox h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: #1b2131;
  padding-bottom: 24px;
}
.vendorTextbox p {
  font-size: 16px;
  color: rgba(27, 33, 49, 0.6);
  padding-bottom: 16px;
  margin-bottom: 0;
  line-height: 19px;
  font-family: "Circular Std Book";
}
.vendorTextbox p a {
  border-bottom: 1px solid #1a4ab9;
  font-weight: 700;
  color: #1a4ab9;
}
.vendorTextbox ul {
  list-style: none;
  color: rgba(27, 33, 49, 0.6);
  padding-bottom: 16px;
  margin-bottom: 0;
}
.vendorTextbox li {
  font-size: 16px;
  display: block;
  padding-bottom: 0;
  margin-bottom: 10px;
  font-family: "Circular Std Book";
}
.vendorFrombox .form-area {
  display: flex;
}
.vendorFrombox .form-div {
  margin-top: 0;
}
.vendorFrombox .form-div:first-child {
  margin-top: 0;
}
.vendorFrombox .form-area .form {
  width: 100%;
  display: inline-block;
}
.vendorFrombox .form-area label {
  font-size: 16px;
  color: rgba(27, 33, 49, 0.6);
  font-family: "Circular Std Book";
}
.vendorFrombox .form-area input,
.vendorFrombox .form-area textarea {
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba(27, 33, 49, 0.4);
  padding: 12px;
  font-size: 16px;
  font-family: "Circular Std Book";
}
.vendorFrombox .phone-number {
  width: 100%;
  font-size: 0;
}
.vendorFrombox .form-area button.submit-button {
  background-color: #00b868;
  border: none;
  padding: 15px 45px;
  width: auto;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}
.vendorTextbox .right_arrow_blue {
  position: relative;
  top: 5px;
}
.vendorFrombox .form-area input::placeholder,
.vendorFrombox .form-area textarea::placeholder {
  color: rgba(27, 33, 49, 0.2);
  font-family: "Circular Std Book";
}
.partners {
  position: relative;
}
.partners:after {
  position: absolute;
  content: "";
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 255, 163, 0.23) 0,
    rgba(196, 196, 196, 0) 100%
  );
  min-height: 716px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  z-index: -1;
}
.partners.sectionGap {
  padding: 54px 0 72px;
}
.logistics .fancyHeading {
  margin-bottom: 136px;
  line-height: 42px;
}
.logistics .d-flex {
  align-items: center;
  margin: 0 -15px;
}
.logistics .account-activity {
  width: 50%;
}
.logistics .infos {
  width: 50%;
  display: block;
  padding-left: 15px;
}
.partners-heading {
  font-size: 24px;
  color: #30bdff;
  font-weight: 700;
  margin-bottom: 32px;
  line-height: 33px;
}
.partners-box {
  background-color: #fff;
  border: 1px solid rgba(27, 33, 49, 0.05);
  height: 208px;
  max-width: 514px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px 0 0;
  margin-bottom: 16px;
}
.partners-box:last-child {
  margin-bottom: 0;
}
.partners-box:hover {
  border: 1px solid rgba(151, 222, 255, 0.6);
  box-shadow: 0 8px 24px rgba(26, 74, 185, 0.1);
}
.partners-box .image {
  width: 30%;
}
.partners-box .image img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
.partners-box .content {
  width: 70%;
  padding-left: 42px;
}
.partners-box .content p {
  font-size: 14px;
  color: rgba(27, 33, 49, 0.6);
  line-height: 19px;
  margin: 0;
  font-family: "Circular Std Book";
}
.payment {
  background-color: rgba(26, 74, 185, 0.1);
}
.logistics {
  padding: 72px 0;
}
.payment .infos {
  text-align: right;
}
.logistics img {
  max-width: 100%;
}
.search-result {
  text-align: left;
  margin-bottom: 0;
}
.search-result-empty:after {
  min-height: 397px;
}
.search-result-empty {
  padding: 100px 0 10px 0;
}
.search-result:after {
  display: none;
}
.search-result-empty .custom-accordian {
  background-color: transparent;
  padding: 0;
}
.search-result-empty .fancyHeading {
  margin: 0;
  text-align: left;
}
.search-result-empty h1 span {
  font-size: unset;
  position: unset;
  display: block;
}
.search-result-empty p {
  font-size: 18px;
  line-height: 25px;
  margin-top: 40px;
  color: rgba(27, 33, 49, 0.6);
}
.search-result-empty .accordian-heading {
  font-weight: 700;
  margin-top: 24px;
  font-size: 24px;
  line-height: 33px;
}
.search-result-empty button {
  padding: 13px 47px;
  margin-top: 32px;
  font-size: 16px;
}
.dekstop-hide {
  display: none;
}
.oops a,
.oops button {
  font-size: 16px;
}
.shopping-cart-empty {
  padding: 0;
}
.shopping-cart-empty:after {
  min-height: 275px;
}
.shopping-cart-empty p {
  margin: 0;
  color: #1b2131;
  margin-top: 16px;
}
.shopping-cart-empty button {
  margin-top: 48px;
}
.oops p span {
  position: unset;
  font-size: 18px;
  display: block;
}
.oops .fancyHeading {
  text-align: left;
  color: #1b2131;
  margin-top: 8px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.oops .fancyHeading:after {
  display: None;
}
.oops .custom-accordian h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: #1a4ab9;
  margin-bottom: 0;
}
.oops p {
  font-size: 18px;
  line-height: 25px;
}
.oops:after {
  display: none;
}
.oops {
  padding: 48px 0 110px 0;
}
.oops a {
  padding: 17px 47px;
  margin-left: 16px;
  vertical-align: middle;
  color: #00b868;
}
.oops-error {
  margin-top: 33px;
}
.oops button {
  margin-top: 0;
}
.btns {
  margin-top: 72px;
}
a.butncon {
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 13px 47px;
  margin-top: 48px;
  float: left;
}
.thankyou {
  /* background-image: url(/pub/static/frontend/Raptor/Desktop/en_US/images/thankyou-bg.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  padding: 150px 200px;
  width: 100%;
}
.thankyou.existing-user {
  text-align: center;
}
.thankyou h1 {
  color: #30bdff;
  line-height: 60px;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 32px;
}
.thankyou.existing-user h1 {
  text-align: center;
  color: #fff;
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 40px;
  font-family: "Circular Std Book";
}
.thankyou h1 span,
.thankyou h2 span,
.thankyou p span {
  display: block;
}
.thankyou p span {
  color: #fff;
}
.thankyou h1:after {
  display: none;
}
.thankyou p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
  text-align: left;
  font-family: "Circular Std Book";
}
.thankyou.existing-user p {
  color: #fff;
  text-align: center;
}
.thankyou.existing-user p span {
  display: inline-block;
  font-family: "Circular Std";
}
.thankyou-info {
  width: 100%;
}
.create-acc {
  margin-top: 24px;
  padding: 11px 48px;
}
.thankyou-left,
.thankyou-right {
  width: 50%;
}
.thankyou-right {
  font-family: "Circular Std Book";
  padding-left: 15px;
}
.password_err {
  color: #fff !important;
  font-size: 12px;
}
.thankyou-right p {
  text-align: left;
  margin: 0;
  color: #30bdff;
  line-height: 25px;
}
.existing-user .thankyou-right p {
  text-align: center;
}
.thankyou-right p span {
  color: #30bdff;
  font-weight: 700;
}
.thankyou-right input {
  font-size: 14px;
}
.thankyou-right label {
  margin-top: 0;
}
.thankyou-right .form.create.account {
  min-width: auto;
  width: 100%;
}
.brandsimage li:hover img {
  transform: scale(1.1, 1.1);
  transition: all 0.5s ease 0s;
}
.catalogue-category li .right_arrow-darkblue {
  position: absolute;
  right: 15px;
  top: 12px;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.catalogue-category li:hover .right_arrow-darkblue {
  right: 10px;
  opacity: 1;
}
.right_arrow-darkblue {
  background-position: -409px -194px;
  height: 15px;
  width: 15px;
}
.catalogue-text {
  position: relative;
  transition: transform 0.3s;
  display: inline-block;
}
.catalogue-text > span {
  vertical-align: middle;
  display: inline-block;
  transition: 0.5s cubic-bezier(0.75, 0, 0.125, 1);
}
.catalogue-text::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  color: rgba(27, 33, 49, 0.6);
  transition: 0.5s cubic-bezier(0.75, 0, 0.125, 1);
}
.catalogue-category li:hover .catalogue-text::before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: 0.5s cubic-bezier(0.75, 0, 0.125, 1);
  color: #1a4ab9;
}
.catalogue-category li:hover .catalogue-text span {
  opacity: 0;
  display: inline-block;
  overflow: visible;
  width: auto;
  transition: 0.5s cubic-bezier(0.75, 0, 0.125, 1);
}
.owl-next span {
  transition: 0.5s ease-in-out;
  display: inline-block;
  width: 100%;
}
.owl-next span:active {
  transform: translateX(10px);
  transition: 0.5s ease-in-out;
}
.owl-prev span {
  transition: 0.5s ease-in-out;
  display: inline-block;
  width: 100%;
}
.owl-prev span:active {
  transform: translateX(-10px);
  transition: 0.5s ease-in-out;
}
.rpt_checkout_btn {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 12px 45px;
  margin-right: 4px;
  display: inline-block;
}
.rpt_green_btn {
  background: #00b868;
  border: 1px solid #00b868;
  color: #fff;
  cursor: pointer;
}
.sweepTop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.sweepTop:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(151, 222, 255, 0.3);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.sweepTop:hover {
  border-color: transparent;
}
.sweepTop:active:before,
.sweepTop:focus:before,
.sweepTop:hover:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.cart-summary .sweepTop:hover {
  background: 0 0;
  color: #00b868;
}
.submit_rfq.sweepTop:hover {
  color: #1a4ab9;
  border-color: #97deff;
}
.submit_rfq.sweepTop:before {
  background: #fff;
}
.buttonbox .uploadimg:hover {
  border: 1px solid #06a761;
  background: #06a761;
}
.buttonbox .uploadimg input[type="file"] {
  display: none;
}
.buttonbox .uploadimg .custom-file-upload {
  display: block;
  margin: 0;
  cursor: pointer;
  padding: 13px 47px;
}
.blogs .main-div:hover {
  transform: scale(1.1, 1.1);
}
.blogs .viewall a {
  margin: 40px auto 0;
}
.blogs .main-div.animated img {
  transform: scale(1) translate(0, 0);
}
.abrasives {
  background-position: -630px -282px;
}
.adhesives {
  background-position: -675px -282px;
}
.appliances-batteries {
  background-position: -720px -282px;
}
.cleaning {
  background-position: -765px -282px;
}
.electrical {
  background-position: -810px -282px;
}
.fasteners {
  background-position: -855px -282px;
}
.furniture-services {
  background-position: -900px -282px;
}
.hand-tools {
  background-position: -945px -282px;
}
.hardware {
  background-position: -990px -282px;
}
.hvac {
  background-position: -1080px -282px;
}
.hydraulics {
  background-position: -1125px -282px;
}
.lab-supplies {
  background-position: -1170px -282px;
}
.lighting {
  background-position: -540px -327px;
}
.lubrication {
  background-position: -630px -327px;
}
.machining {
  background-position: -810px -327px;
}
.material-handling {
  background-position: -990px -327px;
}
.motors {
  background-position: -1035px -282px;
}
.office-supplies {
  background-position: -1215px -282px;
}
.outdoor-equipment {
  background-position: -1260px -282px;
}
.paint-supplies {
  background-position: -273px -327px;
}
.plumbing {
  background-position: -495px -327px;
}
.pneumatics {
  background-position: -675px -327px;
}
.power-tool {
  background-position: -855px -327px;
}
.power-transmission {
  background-position: -1035px -327px;
}
.pumps {
  background-position: -1305px -282px;
}
.raw-materials {
  background-position: -45px -327px;
}
.reference-supplies {
  background-position: -225px -327px;
}
.safety {
  background-position: -360px -327px;
}
.security {
  background-position: -450px -327px;
}
.test-instruments {
  background-position: -720px -327px;
}
.vehicle-maintenance {
  background-position: -900px -327px;
}
.welding {
  background-position: -1080px -327px;
}
.calender {
  background-position: -319px -191px;
  height: 18px;
  width: 18px;
}
.greycalender {
  background-position: -319px -286px;
  height: 18px;
  width: 18px;
  vertical-align: text-bottom;
  margin-right: 10px;
}
.right_arrow_blue {
  width: 15px;
  height: 19px;
  background-position: -138px -192px;
  margin-right: 10px;
}
.qr-code {
  border: 1px solid rgba(27, 33, 49, 0.1);
  padding: 20px;
  max-width: 800px;
  width: 100%;
  margin: 20px auto;
  font-size: 0;
}
.qr-code .qr-details {
  width: 70%;
  display: inline-block;
  border-right: #d9d9d9;
  vertical-align: top;
}
.qr-code h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0;
  color: #1a4ab9;
  font-family: "Circular Std";
}
.qr-code p.scan-qr {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
  color: #1b2131;
  font-family: "Circular Std Book";
}
.qr-code ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px;
}
.qr-code li {
  line-height: 25px;
  font-size: 16px;
  font-family: "Circular Std Book";
  color: rgba(27, 33, 49, 0.6);
}
.qr-code li span {
  margin-right: 5px;
}
.qr-code li:last-child {
  margin-top: 20px;
  color: #1b2131;
}
.qr-code .qr-image {
  width: 30%;
  display: inline-block;
}
.qr-code img {
  border: 1px solid rgba(27, 33, 49, 0.1);
  padding: 5px;
  margin: 0 auto;
  display: block;
  width: 100%;
}
.qr-code a {
  color: #00b868;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  margin: 10px auto 6px;
  display: block;
}
.qr-image p.ph-num {
  text-align: center;
  font-size: 18px;
  color: #1b2131;
}
.grey-tick {
  height: 10px;
  width: 10px;
  background-position: -772px -335px;
}
.flash-msg {
  width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  font-family: "Circular Std";
  text-align: center;
  z-index: 999;
}
.flash-msg p {
  background: #00b868;
  box-shadow: 0 8px 24px rgb(26 74 185 / 10%);
  padding: 16px 32px 16px 50px;
  line-height: 22px;
  color: #fff;
  font-size: 16px;
  font-family: "Circular Std Book";
  display: inline-block;
  position: relative;
}
.flash-msg .white-tick {
  position: absolute;
  left: 25px;
  top: 19px;
  background-position: -771px -518px;
}
.white-tick {
  background-position: -771px -518px;
  height: 13px;
  width: 13px;
}
.faqArea .account-activity {
  width: 35%;
}
.faqArea .infos {
  width: 65%;
}
.faqArea .search-bar {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 64px auto 0;
}
.faqArea .account-activity h3 {
  font-size: 16px;
  line-height: 22px;
  padding-right: 172px;
  font-family: "Circular Std Book";
}
.account-activity h3 span {
  position: absolute;
  right: 125px;
  top: 4px;
}
.faqArea .account-activity h3 a.active .right_arrow_gray {
  background-position: -409px -193px;
  height: 16px;
  width: 16px;
}
.faqArea .account-activity h3 a {
  color: rgba(27, 33, 49, 0.6);
  display: block;
}
.faqArea .account-activity h3 a.active {
  color: #1a4ab9;
  font-weight: 700;
}
.faqArea .account-activity h3:hover::after {
  border-top: 1px solid #1a4ab9;
  border-right: 1px solid #1a4ab9;
}
.faqItems {
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  padding-top: inherit;
}
.faqWrap h2 {
  font-size: 24px;
  line-height: 33px;
  position: relative;
  margin-bottom: 24px;
  font-weight: 700;
  padding-right: 15px;
  color: #1a4ab9;
}
.faqWrap h2 span {
  display: none;
}
.infos .faqWrap h3 {
  font-weight: 700;
  font-size: 16px;
  color: #1b2131;
  line-height: 22px;
}
.faqWrap p {
  color: rgba(27, 33, 49, 0.6);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 0;
  font-family: "Circular Std Book";
  padding-bottom: 24px;
}
.faqWrap ul {
  margin: 0 0 15px 15px;
  list-style-type: none;
  padding: 0;
}
.faqWrap li {
  color: rgba(27, 33, 49, 0.6);
  font-size: 14px;
  line-height: 19px;
  padding: 5px 0;
  position: relative;
}
.faqWrap li span {
  left: -18px;
  top: 2px;
}
.faqArea .dashboard {
  padding: 70px 0;
  max-width: unset;
}
.faqArea .search-bar input {
  border: 1px solid #1a4ab9;
}
.form-error {
  color: #dc3737;
  font-size: 14px;
}
.wwd_images {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.wwdpadding {
  padding-bottom: 0;
  float: left;
  width: 100%;
}
.about_middle,
.aboutbrand,
.aboutcontact {
  float: left;
  width: 100%;
}
.head_bannerback {
  background: linear-gradient(
    180deg,
    rgba(0, 184, 104, 0.15) 19.27%,
    rgba(0, 184, 104, 0) 100%
  );
}
.fmabout_head {
  padding-top: 40px;
  padding-bottom: 30px;
}
.fmabout_head h1 {
  font-size: 40px;
  text-transform: capitalize;
  line-height: 150%;
  color: #1a4ab9;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Circular Std";
  font-weight: 700;
}
.fmabout_head p {
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: rgba(27, 33, 49, 0.6);
  text-align: center;
  font-family: "Circular Std";
}
.fmabout_banner {
  padding-bottom: 20px;
}
.fmabout_textbox1,
.fmabout_textbox1 .fmbox_left,
.fmabout_textbox1 .fmbox_right,
.fmabout_textbox2,
.fmabout_textbox2 .fmbox_left,
.fmabout_textbox2 .fmbox_right {
  float: left;
}
.fmabout_textbox1 .fmbox_left,
.fmabout_textbox1 .fmbox_right,
.fmabout_textbox2 .fmbox_left,
.fmabout_textbox2 .fmbox_right {
  width: 50%;
}
.fmabout_textbox1 .fmbox_right,
.fmabout_textbox2 .fmbox_left {
  padding: 85px 90px;
}
.fmabout_textbox1 .fmbox_right h2,
.fmabout_textbox2 .fmbox_left h2 {
  font-size: 24px;
  font-weight: 700;
  color: #1a4ab9;
  line-height: 140%;
}
.fmabout_textbox1 .fmbox_right p,
.fmabout_textbox2 .fmbox_left p {
  font-size: 14px;
  font-weight: 450;
  color: rgba(27, 33, 49, 0.6);
  line-height: 140%;
  font-family: "Circular Std";
}
.fmabout_textbox1 .fmbox_right a,
.fmabout_textbox2 .fmbox_left a {
  font-size: 14px;
  font-weight: 450;
  color: #00b868;
  line-height: 18px;
  font-family: "Circular Std";
}
.fmabout_textbox1 .fmbox_right a:hover,
.fmabout_textbox2 .fmbox_left a:hover {
  text-decoration: none;
}
.fmabout_textbox1 .fmbox_right a .green-arrow,
.fmabout_textbox2 .fmbox_left a .green-arrow {
  margin-left: 5px;
}
#smrFrame {
  width: 100%;
  height: 370px;
  border: none;
}
#smrFrame svg {
  height: 400px;
}
#smrSourceFrame {
  width: 100%;
  height: 450px;
  border: none;
  overflow: hidden;
}
.zendex #launcher {
  bottom: 54px !important;
}
.d-flex.oops-error {
  align-items: inherit;
}
.d-flex.oops-error .custom-accordian {
  padding-top: 30px;
}
.d-flex.oops-error .btns {
  margin-top: 40px;
}
a.newlink:hover {
  text-decoration: none;
}
.restriction_prod .myContainer ol {
  margin-left: 15px;
}
.restriction_prod.privacy-policy ol li ul {
  margin: 12px 0 0 0;
}
.restriction_prod .myContainer ol li {
  padding: 0 0 0 10px;
  margin-bottom: 7px;
}
.restriction_prod .myContainer ul li {
  padding: 0 0 0 40px;
}
.accordion-innerWrp a {
  font-size: 14px;
  font-weight: 400;
  font-family: "Circular Std Book";
}
.accordion-innerWrp a:active,
.accordion-innerWrp a:hover {
  color: #1979c3;
}
span#crosshide {
  position: absolute;
  top: -10px;
  right: -7px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
}
.grainger-mobbg {
  display: none !important;
}
.grainger-mobbigbg {
  display: none !important;
}
.graingerbanner-wrap {
  position: relative;
  z-index: 1;
}
.quotecart-index-rfqquote .internalariba {
  display: none;
}
.promotionalwrap {
  padding: 0 0 40px;
}
.aribalogo img {
  width: 100%;
}
.aribabanner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.aribalogo {
  width: 22%;
  height: 90px;
  border: 1px solid #e7e8ea;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aribacont {
  width: 78%;
  height: 90px;
  background: #faba00;
  padding: 11px 20px 11px 90px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.aribacont h3 {
  line-height: 1.1;
  font-size: 26px;
  font-weight: 600;
  color: #000;
  font-family: "Circular Std";
  margin-bottom: 8px;
}
.aribacont p {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  font-family: "Circular Std Book";
  margin-bottom: 0;
}
.aribacont p span {
  font-weight: 600;
}
.promotionalwrap .owl-theme .owl-nav {
  margin-top: 0;
}
.promotionalwrap .owl-nav :is(.owl-prev, .owl-next) {
  top: 25%;
}
.promotionalwrap .owl-theme .owl-nav button {
  line-height: 0;
  box-shadow: 0 0 5px rgba(127, 137, 161, 0.2);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promotionalwrap .owl-theme .owl-nav button.owl-prev {
  position: absolute;
  left: -25px;
  background: #fff;
}
.promotionalwrap .owl-theme .owl-nav button.owl-next {
  position: absolute;
  right: -25px;
  background: #fff;
}
.promotionalwrap .owl-theme .owl-nav button:hover {
  background: #fff;
}
.promotionalwrap .owl-theme .owl-nav button:focus {
  outline: 0;
  background-color: #fff !important;
}
.promotionalwrap .owl-nav .icons__right {
  border: solid #1f243273;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.promotionalwrap .owl-nav .icons__left {
  border: solid #1f243273;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.promotionalwrap .owl-dots {
  display: block !important;
}
.promotionalwrap .owl-dots button:focus {
  outline: 0;
}
.promotionalwrap .owl-theme .owl-dots .owl-dot.active span,
.promotionalwrap .owl-theme .owl-dots .owl-dot:hover span {
  background: #30bdff;
}
.cms-home .internalariba {
  display: none;
}
.aribacont h3 br {
  display: none;
}
.owl-item .aribacont,
.owl-item .aribalogo {
  height: 120px;
}
.promotionalwrap .owl-carousel .item a:hover {
  text-decoration: none;
}
.checkout-cart-index .promotional-banner,
.checkout-index-index .promotional-banner,
.checkout-onepage-success .promotional-banner {
  display: none;
}
.quotecart-index-index .internalariba {
  margin-bottom: 5px;
}
.promotionalwrap .owl-carousel .item a:active,
.promotionalwrap .owl-carousel .item a:focus,
.promotionalwrap .owl-carousel .item a:hover {
  text-decoration: none;
  display: block;
}
.promotionalwrap button.disabled {
  display: none !important;
}
.internalariba a:hover {
  text-decoration: none;
}
.paymenttrust {
  width: 100%;
  position: relative;
  z-index: 1;
}
.paymro_icon {
  float: left;
  width: 100%;
  padding-bottom: 40px;
}
.paymenttrust .payall_icon {
  /* background: url(../images/paymenttrust.svg) no-repeat; */
  display: inline-block;
}
.paymenttrust .mro {
  background-position: -62px -44px;
  width: 121px;
  height: 74px;
  float: left;
}
.paymenttrust .options {
  background-position: -242px -44px;
  width: 121px;
  height: 74px;
  float: left;
}
.paymenttrust .net30 {
  background-position: -422px -44px;
  width: 121px;
  height: 74px;
  float: left;
}
.paymenttrust .trustbox {
  background: #fff;
  box-shadow: 0 30px 60px rgba(133, 149, 158, 0.15);
  padding: 32px 40px;
  border-bottom: 3px solid #fff;
  min-height: 340px;
  transition: 0.5s all;
}
.paymenttrust .trustbox:hover {
  border-bottom: 3px solid #1a4ab9;
  transform: scale(1.1, 1.1);
}
.paymenttrust .trustbox h3 {
  color: #1a4ab9;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin: 42px 0 0 0;
}
.paymenttrust .trustbox p {
  color: rgba(27, 33, 49, 0.6);
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 16px 0 0 0;
  font-family: "Circular Std Book";
}
.myContainerempty {
  max-width: 1090px !important;
  padding-left: 32px;
}
.myContainerempty img {
  float: right;
}
.myContainerempty a.butncon {
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 13px 47px;
  margin-top: 13px;
  float: left;
}
.myContainerempty ul {
  list-style: none;
  font-size: 16px;
  margin: 0;
  padding: 0;
  padding-top: 25px;
}
.myContainerempty ul li {
  margin: 0;
  padding-bottom: 7px;
  line-height: 32px;
  position: relative;
  padding-left: 26px;
}
.myContainerempty ul li::before {
  display: inline-block;
  content: "";
  border-radius: 8px;
  position: absolute;
  height: 8px;
  left: 0px;
  top: 12px;
  width: 8px;
  margin-right: 16px;
  background-color: #1b2131;
}
.myContainerempty .quoteCartt {
  padding: 57px 0;
  width: 62%;
}
.myContainerempty .account-activity {
  width: 38%;
}
.myContainerempty .d-flex.quotecartM {
  align-items: flex-start;
}
.myContainerempty a.butncon.quteBtnstrip {
  display: none;
}

@media screen and (max-width: 1366px) {
  .myContainerempty .quoteCartt {
    padding: 48px 0;
  }
  .myContainerempty ul {
    padding-top: 20px;
  }
  .myContainerempty {
    padding-left: 33px;
  }
}

@media screen and (max-width: 1280px) {
  .paymenttrust .trustbox {
    min-height: 385px;
  }
  .contact-us .box .image {
    height: 50px;
  }
  .contact-us .box .contact-info {
    padding-left: 15px;
  }
  .talk-quote .get-a-quote p br {
    display: none;
  }
  .benefits-of-trade-account .benefits .benefits-box {
    padding: 25px 30px 40px 40px;
  }
  .invoice-documentation-cards .cards p br {
    display: none;
  }
  .logistics .d-flex {
    margin: 0;
  }
  .bannertext label {
    max-width: 416px;
  }
  label.sticky-search {
    transform: translate(304px, -40px);
  }
}
@media screen and (max-width: 1024px) {
  .paymenttrust .trustbox {
    min-height: 495px;
  }
  .paymenttrust .trustbox:hover {
    transform: unset;
  }
  .aribacont,
  .aribalogo {
    height: 90px;
  }
  .promotionalwrap .owl-theme .owl-nav button.owl-prev {
    left: -15px;
  }
  .promotionalwrap .owl-theme .owl-nav button.owl-next {
    right: -15px;
  }
  .sectionGap {
    padding: 30px 0;
  }
  .where-we-deliver img {
    margin: 20px auto 0;
  }
  .whoweare.white-bg {
    padding: 48px 0 0;
  }
  .blurbs {
    margin-right: 0;
    width: 49%;
    margin-top: 15px;
  }
  .about-us-blurbs {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  label.sticky-search {
    transform: translate(324px, -40px);
  }
  .bannertext input {
    font-size: 12px;
    padding: 12px 0 12px 12px;
  }
  .bannertext h1 {
    font-size: 26px;
    line-height: 33px;
  }
  .bannertext label {
    width: 305px;
  }
  .sourceoutside .outsideleft p br {
    display: none;
  }
  .buttonbox .submit_rfq {
    font-size: 14px;
    padding: 15px;
  }
  .buttonbox {
    padding-top: 10px;
  }
  .sourceoutside .outsideleft p {
    padding: 0;
  }
  .buttonbox .uploadimg .custom-file-upload {
    padding: 13px 20px;
  }
  .card2 {
    width: 140px;
  }
  .card1 {
    width: 100px;
  }
  .card3 h3 {
    font-size: 25px;
  }
  .card3 p {
    font-size: 11px;
    line-height: 22px;
  }
  .card3 {
    height: 90px;
    padding: 12px 0 19px 5px;
  }
  .contact-us .contact-details .box a {
    flex-direction: column;
  }
  .contact-us .box a .image {
    width: 100%;
  }
  .contact-us .box a .contact-info {
    width: 100%;
  }
  .contact-us .box .contact-info {
    padding: 10px 0 0;
  }
  .contact-us .box .contact-info h3,
  .contact-us .box .contact-info p {
    text-align: center;
  }
  .b2b-banner h1 {
    font-size: 30px;
  }
  .b2b-banner p {
    font-size: 20px;
    line-height: 26px;
  }
  .mrgn {
    margin-top: 30px;
  }
  .terms-and-services .box h6 {
    margin-top: 20px;
  }
  .terms-and-services .box p {
    margin-top: 10px;
  }
  .terms-and-services .box {
    min-height: 365px;
  }
  .invoice-documentation-cards .cards h6 br,
  .invoice-documentation-cards .cards p br {
    display: none;
  }
  .invoice-documentation-cards .cards {
    padding: 20px 40px;
    min-height: 225px;
  }
  .get-form {
    padding: 0;
  }
  .trade-details:after {
    max-width: 83%;
    top: -16px;
  }
  .benefits-of-trade-account .benefits .benefits-box p br {
    display: none;
  }
  .trade-details a {
    font-size: 14px;
  }
  .benefits-of-trade-account .benefits .benefits-box {
    padding: 25px 25px 25px 30px;
  }
  .talk-quote .lets-talk h3 {
    font-size: 18px;
    margin-top: 15px;
  }
  .contact-dropflip {
    padding: 0 50px;
  }
  .grey-next {
    left: -160px;
  }
  .partners-box .content {
    padding-left: 15px;
  }
  .partners-box {
    padding: 0 12px;
  }
  .search-result-empty h1 span {
    display: inline-block;
  }
  .oops a {
    margin-left: 10px;
  }
  .thankyou {
    padding: 150px 50px;
  }
  .shopping-cart-empty p {
    font-size: 16px;
    line-height: 22px;
  }
  .oops .custom-accordian h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .oops .fancyHeading {
    font-size: 18px;
    line-height: 24px;
  }
  .oops p {
    font-size: 16px;
    line-height: 19px;
  }
  .search-result-empty .fancyHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .search-result-empty .accordian-heading {
    font-size: 18px;
    line-height: 24px;
    margin-top: 15px;
  }
  .search-result-empty p {
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
  }
  .blogs .main-div {
    transition: unset;
  }
  .blogs .main-div:hover {
    transform: unset;
  }
  .brandsimage li:hover img {
    transition: unset;
    transform: unset;
  }
}
@media screen and (max-width: 768px) {
  .aribacont {
    padding: 11px 20px 11px 50px;
  }
  .aribacont h3 {
    font-size: 24px;
  }
  .aribacont p {
    font-size: 16px;
  }
  .owl-item .aribacont,
  .owl-item .aribalogo {
    height: 85px;
  }
  .aribacont,
  .aribalogo {
    height: 85px;
  }
  .allcard:first-child {
    margin: 70px 0 80px 0;
  }
  .vendorPage {
    display: flex;
    flex-wrap: wrap;
  }
  .vendorFrombox,
  .vendorTextbox {
    width: 100%;
  }
  .vendorFrombox {
    order: 2;
  }
  .vendorTextbox {
    padding-right: 10px;
    order: 1;
  }
  .vendorTextbox h3 {
    margin-top: 15px;
    padding-bottom: 16px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px;
  }
  .vendorTextbox p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 19px;
  }
  .vendorTextbox p:last-child {
    font-size: 16px;
    line-height: 22px;
  }
  .vendorTextbox li {
    padding: 0;
    margin-bottom: 10px;
  }
  .vendorTextbox ul {
    margin: 0;
  }
  .vendorTextbox p:first-child {
    font-size: 14px;
  }
  .whoweare .whoweareleft {
    padding-right: 40px;
  }
  label.sticky-search {
    transform: translate(250px, -45px);
  }
  .bannertext label {
    width: 223px;
  }
  .fmabout_textbox1 .fmbox_right,
  .fmabout_textbox2 .fmbox_left {
    padding: 50px 30px;
  }
  .fmabout_textbox1 .fmbox_right h2,
  .fmabout_textbox2 .fmbox_left h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .uploaded-files {
    font-size: 12px;
  }
  .paymenttrust {
    margin-top: 0;
  }
  .paymenttrust .trustbox {
    margin-bottom: 25px;
    min-height: Auto;
    padding: 28px 35px;
    margin-left: 25px;
    margin-right: 25px;
  }
  .paymenttrust .trustbox p {
    font-size: 14px;
  }
  .paymenttrust .trustbox h3 {
    font-size: 16px;
    margin: 24px 0 0 0;
  }
  .page-selector .down_arrow_gray {
    background-position: -48px -287px;
  }
  .page-selector ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
  }
  .page-selector li {
    color: #000;
    font-size: 14px;
    margin: 0;
    line-height: 0;
  }
  .page-selector-dropdown {
    position: absolute;
    background-color: #1a4ab9;
    width: 100%;
    left: 0;
    top: 29px;
    font-size: 0;
    z-index: 99;
    display: none;
  }
  .breadcrumbs .page-selector-dropdown a,
  .breadcrumbs .page-selector-dropdown span {
    width: 50%;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    color: #fff;
    padding: 5px 10px;
  }
  .vendorTextbox p:last-child {
    font-size: 16px;
    line-height: 22px;
    padding-right: 10px;
  }
  .grainger-mobbigbg {
    display: block !important;
  }
  .grainger-desktopbg {
    display: none !important;
  }
  .grainger-mobbg {
    display: none;
  }
  .mcmaster_Carr h1 img {
    width: 90px;
    display: block;
    margin: 0 auto 10px;
  }
  .mcmaster_Carr h1:after {
    bottom: -4px;
  }
  .mcmaster_Carr .fancyHeading {
    font-size: 18px;
    display: block;
  }
  .restriction_prod .myContainer ol li {
    padding: 0 0 0 5px;
  }
  .restriction_prod .myContainer ul li {
    padding: 0 0 0 22px;
  }
  .blurbs {
    width: 100%;
    height: auto;
  }
  .about-us-blurbs {
    margin-top: 10px;
  }
  .fancyHeading {
    font-size: 16px;
    line-height: 22px;
  }
  .fmabout_head p br {
    display: none;
  }
  #smrFrame {
    height: 370px;
    margin-top: 0;
  }
  #smrSourceFrame {
    height: 240px;
  }
  h1,
  h1.fancyHeading {
    font-size: 24px;
  }
  .banner {
    padding-top: 24px;
  }
  .banner-content {
    flex-direction: column;
  }
  .bannertext {
    width: 100%;
    padding-right: 0;
    margin-top: 0;
  }
  .bannerimage {
    width: 100%;
    text-align: center;
  }
  .bannertext label {
    margin-top: 24px;
    width: 90%;
    margin: 24px auto 0;
    display: block;
    z-index: 999;
  }
  .bannertext h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    margin-top: 0;
  }
  .bannertext input {
    border: 1px solid #1a4ab9;
    padding: 12px;
  }
  .banner:after {
    width: 100%;
    top: 0;
  }
  .bannertext button {
    padding: 8px 10px;
  }
  .brandsimage {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .brandsimage li:nth-child(4),
  .brandsimage li:nth-child(5) {
    display: none;
  }
  .buttonbox .submit_rfq {
    background-color: transparent;
    float: none;
  }
  .buttonbox .uploadimg {
    display: none;
  }
  .buttonbox .mobile-documnet-upload {
    font-family: "Circular Std Book";
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    width: 160px;
    padding: 13px 0;
    border: 1px solid #00b868;
    background: #00b868;
    display: inline-block;
    margin-top: 15px;
    padding-left: 5px;
  }
  .buttonbox .mobile-documnet-upload span {
    margin-right: 5px;
    position: relative;
    top: 2px;
    left: 5px;
  }
  .brandsimage ul {
    margin: 20px 0 0 0;
  }
  .brandsimage li {
    width: 33.333%;
    transition: 0.5s all;
    overflow: hidden;
  }
  .brands {
    padding: 54px 0 30px;
  }
  .viewall {
    margin: 30px auto 0;
  }
  .viewall a {
    font-size: 14px;
    line-height: 19px;
    padding: 13px 40px;
  }
  .blogs .viewall a {
    margin: 20px auto 0;
  }
  .sourceoutside .outsideleft h4 {
    font-size: 16px;
    font-family: "Circular Std Book";
    font-weight: 400;
  }
  .sourceoutside img {
    padding-top: 0;
  }
  .buttonbox .submit_rfq,
  .buttonbox .uploadimg {
    font-size: 16px;
    padding: 13px 0;
    width: 160px;
    text-align: center;
  }
  .sourceoutside .outsideleft p {
    font-size: 14px;
    padding: 24px 0;
    margin: 0;
  }
  .buttonbox {
    padding-top: 30px;
  }
  .blogs {
    padding: 40px 0;
    width: 100%;
  }
  .blogs .col-6:first-child {
    padding-right: 4px;
    padding-left: 15px;
  }
  .blogs .col-6:nth-child(2) {
    padding-left: 4px;
    padding-right: 15px;
  }
  .allcardbox,
  .card3 {
    width: 100%;
  }
  .allcardbox {
    margin-bottom: 60px;
  }
  .card3 h3 {
    font-size: 28px;
    line-height: 40px;
    margin: 0;
  }
  .cardbox {
    margin-bottom: 40px;
  }
  .allcard {
    margin-bottom: 0;
    margin-top: 40px;
    display: block;
    padding: 0 60px;
  }
  .allcard:first-child {
    margin-top: 40px;
  }
  .whoweare .whoweareleft {
    padding: 0;
  }
  .whoweareleft p {
    margin-top: 35px;
    font-size: 12px;
    line-height: 18px;
    padding: 0 60px;
  }
  .whoweareleft p.secondpara {
    display: none;
  }
  .whoweare {
    padding: 40px 0 0 0;
  }
  .where-we-deliver {
    padding: 25px 0 0 0;
  }
  .animated-cards .cardbox .card1 {
    transform: rotate(30deg);
    left: 24px;
    border: 1px solid rgba(26, 74, 185, 0.1);
    box-shadow: 0 4px 24px rgb(26 74 185 / 10%);
  }
  .animated-cards .cardbox .card2 {
    transform: rotate(15deg);
    width: 120px;
  }
  .card3 {
    height: 95px;
  }
  .card3 p {
    font-size: 14px;
  }
  .pulse1,
  .pulse2,
  .pulse3 {
    display: none;
  }
  .pulse {
    top: 40%;
    width: 10px;
    height: 10px;
  }
  .blogs .blog-hide {
    display: none;
    transition: 0.5s all;
    overflow: hidden;
  }
  .blogs .main-div:hover {
    background-color: #fff;
  }
  .blogs .main-div h4 {
    padding: 7px 10px;
    font-size: 11px;
    line-height: 13px;
    font-family: "Circular Std Book";
    margin: 0;
  }
  .blogs .main-div p {
    padding: 0 10px;
    font-size: 10px;
    line-height: 13px;
    margin: 0;
  }
  a.mobile-button {
    display: block;
  }
  .catalogue-category {
    padding: 40px 0;
  }
  .categoery_slide .owl-stage {
    padding-left: 0 !important;
  }
  .catalogue-category ul {
    padding: 0 0;
  }
  .catalogue-category li a .left-icon {
    margin-right: 20px;
  }
  .contact-us .box a .image {
    width: 18%;
  }
  .contact-us .box a .contact-info {
    width: 82%;
  }
  .contact-us .box .contact-info h3 {
    font-size: 14px;
    text-align: left;
  }
  .contact-us .box .contact-info p {
    text-align: left;
  }
  .contact-us .contact-details {
    flex-direction: column;
  }
  .contact-us p.info {
    font-size: 12px;
    line-height: 16px;
    padding: 0 15px;
    margin: 0 0 32px 0;
  }
  .contact-us .contact-details .box {
    width: 100%;
    margin: 0 0 24px 0;
  }
  .contact-us .contact-details .box a {
    flex-direction: row;
    padding: 24px;
  }
  .contact-us .box .contact-info {
    padding: 0 10px;
    width: 80%;
  }
  .contact-us h2 {
    font-size: 16px;
    line-height: 22px;
  }
  .outsideleft {
    padding: 24px 0 40px 0;
  }
  .sourceoutside .col-sm-12.col-md-6 {
    padding: 0 40px;
  }
  .sourceoutside {
    padding: 40px 0;
    background: linear-gradient(282.93deg, #1a4ab9 1.49%, #30bdff 113.13%);
  }
  .document-cards,
  .terms-and-services .cards {
    flex-direction: column;
  }
  .terms-and-services .box {
    min-height: auto !important;
    margin-top: 30px;
    padding: 40px;
    width: 100%;
  }
  .b2b-banner {
    padding: 76px 0;
  }
  .b2b-banner h1 {
    font-size: 18px;
  }
  .b2b-banner p {
    font-size: 16px;
    margin: 15px 0 0 0;
  }
  .terms-and-services {
    margin-top: 32px;
  }
  .b2b-banner p br,
  .contact-us p br {
    display: none;
  }
  .invoice-documentation-cards .cards {
    min-height: auto;
    margin-top: 20px;
    width: 100%;
    padding: 32px 50px;
  }
  .mrgn {
    margin-top: 60px;
  }
  .contact-us .box .image {
    width: 20%;
    height: 55px;
  }
  .invoice-documentation-cards .cards,
  .terms-and-services .box {
    margin: 0 0 20px 0;
  }
  .invoice-documentation-cards {
    margin: 0 0 20px 0;
  }
  .dashboard {
    flex-direction: column;
  }
  .account-activity {
    display: none;
  }
  .lightblue {
    background-color: transparent;
    padding: 15px 0;
  }
  .payment-method .account-number,
  .payment-method .name {
    font-size: 12px;
  }
  .dashboard {
    padding: 0;
  }
  .get-form {
    padding: 0;
    max-width: 100%;
  }
  .talk-quote .get-a-quote h1,
  .talk-quote .lets-talk h1 {
    font-size: 20px;
  }
  .talk-quote .get-a-quote h1 {
    line-height: 24px;
  }
  .talk-quote .get-a-quote h1:after,
  .talk-quote .lets-talk h1:after,
  .trade-account h2:after {
    top: 32px;
  }
  .trade-details a {
    font-size: 14px;
  }
  .trade-account .talk-quote input,
  .trade-account .talk-quote select {
    height: 48px;
    margin: 0;
    font-size: 12px;
    line-height: unset;
  }
  .trade-account .select-down {
    top: 42px;
  }
  .trade-account {
    padding: 20px 0;
  }
  .trade-account p span {
    display: inline;
  }
  .trade-account p {
    font-size: 14px;
    line-height: 19px;
    margin-top: 42px;
    margin-bottom: 45px;
  }
  .trade-account a.next-btn {
    display: inline-block;
    opacity: 0.5;
  }
  .trade-account a.next-btn:hover {
    color: #fff;
  }
  .trade-account h2 {
    font-size: 18px;
    line-height: 25px;
  }
  .trade-account .talk-quote .get-a-quote label {
    margin-bottom: 2px;
    line-height: 22px;
  }
  .trade-account .form-div,
  .trade-account .full-width {
    margin-top: 0;
  }
  .trade-account .group .form-div {
    width: 100%;
  }
  .trade-account h1 {
    font-size: 18px;
  }
  .trade-account h1:after {
    top: 32px;
  }
  .trade-details:after {
    max-width: 85%;
    top: -17px;
  }
  .talk-quote .get-a-quote input,
  .talk-quote .get-a-quote label,
  .talk-quote .get-a-quote p,
  .talk-quote .get-a-quote textarea {
    font-size: 14px;
  }
  .talk-quote .get-a-quote p {
    margin-top: 42px;
    margin-bottom: 10px;
  }
  .talk-quote .get-a-quote label {
    margin-bottom: 2px;
  }
  label.fileBtn {
    margin: 0;
  }
  .functionality .group .form-div {
    width: 100%;
  }
  .talk-quote .get-a-quote .group {
    display: block;
  }
  .talk-quote .get-a-quote .group label {
    width: 100%;
    margin-left: 0;
  }
  .cms-submit-purchase-order .form-div {
    margin-top: 10px;
  }
  .talk-quote .get-a-quote input,
  .talk-quote .get-a-quote select {
    height: 48px;
    margin: 0;
  }
  .talk-quote .get-a-quote select {
    font-size: 12px;
    line-height: unset;
  }
  .cms-submit-purchase-order .select {
    color: #fff;
  }
  .talk-quote .group .form-div .select-down {
    top: 42px;
  }
  .get-a-quote .group label select {
    font-size: 14px;
  }
  .company-details,
  .customer-details,
  .invoice-address {
    margin-top: 25px;
  }
  .company-details,
  .invoice-address {
    display: none;
  }
  .benefits-of-trade-account .benefits .benefits-box {
    margin: 0 2px;
  }
  .benefits-of-trade-account {
    margin-top: 40px;
  }
  .benefits-of-trade-account .benefits {
    margin-top: 48px;
    padding: 0 50px;
  }
  .benefits-of-trade-account .benefits .benefits-box p {
    font-size: 12px;
    line-height: 20px;
  }
  .benefits-of-trade-account .benefits .benefits-box p br {
    display: none;
  }
  .get-a-quote .group label ul.country-dropdown {
    top: 73px;
  }
  .get-a-quote .group label.country:after {
    top: 47px;
  }
  .d_flex {
    flex-direction: column;
  }
  .talk-quote .lets-talk,
  .talk-quote .quote-form {
    width: 100%;
    padding: 0;
  }
  .talk-quote .lets-talk {
    display: none;
  }
  .cms-request-for-quote .group .form-div {
    width: 100%;
  }
  .cms-request-for-quote .talk-quote select {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
  }
  .cms-request-for-quote .talk-quote option {
    font-size: 14px;
    color: #1b2131;
    line-height: 19px;
    margin-bottom: 0;
  }
  .page-selector li + li::before {
    display: none;
  }
  .tab-content {
    margin-top: 8px;
  }
  .tab-content li {
    margin: 0 0 5px 0;
    width: 50%;
  }
  .page-selector {
    background: rgba(151, 222, 255, 0.3);
    padding: 8px 15px;
    display: inline-block;
    width: 80px;
    position: absolute;
    right: 15px;
    top: -4px;
  }
  .tab-content a {
    font-size: 12px;
    line-height: 16px;
  }
  .listing {
    display: none;
  }
  .our-catalouge {
    display: none;
  }
  .custom-accordian {
    background-color: #fff;
    padding: 24px 0;
  }
  .accordian-dropdown {
    border: 1px solid rgba(27, 33, 49, 0.05);
    padding: 16px;
    box-shadow: 0 8px 24px rgba(26, 74, 185, 0.1);
    margin-bottom: 24px;
  }
  .custom-accordian p {
    font-size: 12px;
    margin-top: 13px;
  }
  .custom-accordian h2.accordian-heading {
    font-size: 14px;
    line-height: 24px;
  }
  .down-arrow {
    transition: unset;
  }
  .custom-accordian .down-arrow {
    top: 7px;
  }
  .custom-accordian h1 {
    margin-bottom: 56px;
    line-height: 22px;
  }
  .custom-accordian h1:after {
    top: 38px;
  }
  .custom-accordian li {
    font-size: 12px;
    margin-bottom: 5px;
  }
  span.company-number {
    display: none;
  }
  .fancyHeading:after {
    bottom: -4px;
  }
  .accordian-dropdown .active .down-arrow {
    transform: unset;
    background-position: -2px -198px;
  }
  .table .static_all,
  .table .terms_contentnew {
    font-size: 11px;
  }
  .table > tbody > tr > td {
    padding: 4px;
  }
  .industries {
    display: block;
  }
  .industries .industries-about {
    padding-left: 0;
  }
  .industries-about h3 {
    margin-top: 16px;
  }
  .industries-served .fancyHeading {
    font-size: 16px;
    line-height: 22px;
  }
  .industries-about p {
    font-size: 12px;
    margin-top: 8px;
  }
  .industries img {
    max-width: 100%;
    width: 100%;
  }
  .cms-vendor-registration .fancyHeading:after {
    bottom: -4px;
  }
  .cms-vendor-registration .industries-served h1 {
    margin-bottom: 20px;
  }
  .privacy-policy li,
  .privacy-policy p {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 30px;
  }
  .industries-about h3,
  .privacy-policy h3 {
    font-size: 14px;
  }
  .industries-served h1,
  .privacy-policy h1 {
    margin-bottom: 48px;
  }
  .industries-served,
  .privacy-policy {
    padding: 28px 0;
    background-color: #fff;
  }
  .privacy-policy ul {
    margin-top: 13px;
  }
  .privacy-policy li {
    padding: 0 0 0 22px;
  }
  .logistics .account-activity {
    display: block;
  }
  .logistics .d-flex {
    flex-direction: column;
    margin: 0;
  }
  .logistics .infos {
    padding: 0;
  }
  .logistics .account-activity,
  .logistics .infos,
  .partners-box .content,
  .partners-box .image {
    width: 100%;
  }
  .partners-box {
    max-width: 100%;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    height: auto;
  }
  .partners-box .image img {
    margin: 0;
  }
  .partners-box .content {
    padding: 0;
  }
  .logistics .fancyHeading {
    margin-bottom: 16px;
    font-size: 20px;
  }
  .logistics img {
    margin: 0 auto;
    display: block;
  }
  .logistics,
  .partners.sectionGap {
    padding: 32px 0;
  }
  .partners-heading {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
  .payment.logistics .infos {
    order: 1;
  }
  .payment.logistics .account-activity {
    order: 2;
  }
  h1.search-result.fancyHeading {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }
  .search-result-empty {
    padding: 32px 0 48px 0;
  }
  .search-result-empty .infos {
    text-align: center;
  }
  .search-result-empty .accordian-heading {
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
  }
  .search-result-empty p {
    font-size: 14px;
    line-height: 19px;
    margin-top: 0;
    color: #1b2131;
  }
  .mobile-hide {
    display: none;
  }
  .dekstop-hide {
    display: block;
  }
  .search-result-empty button {
    margin: 24px auto 0;
  }
  .shopping-cart-empty p {
    font-size: 16px;
    margin-top: 24px;
  }
  .oops .custom-accordian h1 {
    font-size: 24px;
    line-height: 33px;
    margin-top: 0;
  }
  .oops .fancyHeading {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
  }
  .oops p,
  .oops p span {
    line-height: 19px;
    font-size: 14px;
    color: rgba(27, 33, 49, 0.6);
  }
  .oops a {
    width: 195px;
    text-align: center;
    margin: 10px auto;
    padding: 13px 47px;
  }
  a.butncon {
    float: none;
    margin: 0 auto;
    width: 240px;
    clear: both;
  }
  .shopping-cart-empty {
    padding: 32px 0 48px 0;
  }
  .thankyou {
    padding: 80px 15px 45px;
    display: block;
  }
  .thankyou-left,
  .thankyou-right {
    width: 100%;
  }
  .thankyou-right {
    padding: 0;
  }
  .thankyou h1 {
    line-height: 33px;
    font-size: 24px;
    margin-bottom: 16px;
  }
  .thankyou h1 span {
    display: inline-block;
  }
  .thankyou p {
    font-size: 12px;
    line-height: 16px;
    padding-top: 10px;
    margin-bottom: 24px;
  }
  .thankyou p span {
    padding-top: 5px;
  }
  .thankyou-right form {
    padding-top: 0;
  }
  .thankyou-right label {
    font-size: 14px;
    line-height: 19px;
  }
  .thankyou-right input {
    font-size: 12px;
  }
  .thankyou-right .field {
    margin-top: 24px;
  }
  .thankyou.existing-user {
    padding: 80px 0;
  }
  .thankyou h2 {
    font-size: 14px;
    line-height: 24px;
  }
  .flash-msg p {
    font-size: 13px;
    padding: 15px 10px 15px 25px;
  }
  .flash-msg .white-tick {
    left: 8px;
    top: 19px;
  }
  .upload-picture {
    max-width: 95%;
    padding: 30px 15px;
  }
  .upload-picture .talk-quote .get-a-quote input {
    height: auto;
  }
  .upload-picture.talk-quote .get-a-quote p {
    font-size: 14px;
    line-height: 19px;
    padding: 20px 0;
  }
  .upload-picture input,
  .upload-picture label {
    font-size: 14px;
    line-height: 19px;
    height: Auto;
  }
  .upload-picture .form-div input {
    height: Auto;
  }
  .upload-picture button {
    font-size: 14px;
  }
  .upload-picture .uploaded-files {
    font-size: 12px;
    line-height: 19px;
  }
  .faqArea {
    padding: 10px 0;
  }
  .faqArea h1 {
    margin-bottom: 80px;
  }
  .faqArea .dashboard {
    padding: 0;
  }
  .mcm_faq.dashboard {
    padding: 40px 20px;
  }
  .mcm_faqWrap {
    width: 100%;
  }
  .faqArea .search-bar {
    display: none;
  }
  .faqArea .account-activity {
    display: none;
  }
  .faqArea .infos {
    width: 100%;
  }
  .faqWrap h2 {
    font-size: 16px;
    line-height: 25px;
    color: #1a4ab9;
    position: relative;
    margin-bottom: 24px;
  }
  .faqWrap h2 span {
    display: block;
  }
  .faqWrap h2.active {
    font-weight: 700;
    color: #1a4ab9;
  }
  .faqWrap h2 .down-arrow {
    background-position: -50px -289px;
    margin: 0;
    width: 15px;
    top: 7px;
  }
  .faqWrap h2.active .down-arrow {
    background-position: -50px -196px;
    transform: rotate(180deg);
  }
  .infos .faqWrap h3 {
    margin-bottom: 0;
  }
  .faqWrap p {
    margin-top: 5px;
    padding-bottom: 10px;
  }
  .mcm_faqInnerWrap {
    margin-bottom: 10px;
  }
  .faqWrap h2.active .down_arrow_gray {
    background-position: -4px -189px;
  }
  .faqWrap h2 .down_arrow_gray {
    height: 17px;
    background-position: -49px -283px;
  }
  .fmabout_head {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .fmabout_head h1 {
    font-size: 22px;
    line-height: 33px;
  }
  .fmabout_head p {
    font-size: 14px;
  }
  .fmabout_textbox1 .fmbox_left,
  .fmabout_textbox1 .fmbox_right,
  .fmabout_textbox2 .fmbox_left,
  .fmabout_textbox2 .fmbox_right {
    width: 100%;
    margin-bottom: 30px;
  }
  .fmabout_banner {
    padding-bottom: 40px;
  }
  .fmabout_textbox1 .fmbox_right,
  .fmabout_textbox2 .fmbox_left {
    padding: 0;
  }
  .fmabout_textbox2 {
    display: flex;
    flex-direction: column-reverse;
  }
  .fmabout_textbox1 .fmbox_right h2,
  .fmabout_textbox2 .fmbox_left h2 {
    font-size: 16px;
  }
  .d-flex.oops-error .custom-accordian {
    padding-top: 0;
  }
  .vendorFrombox .form-div {
    margin-top: 0;
  }
  .talk-quote .get-a-quote textarea#interest {
    margin: 0;
  }
  .myContainerempty h1.search-result.fancyHeading {
    text-align: left;
    font-size: 28px;
    line-height: 40px;
  }
  .myContainerempty .infos {
    text-align: left;
  }
  .myContainerempty {
    padding-left: 16px;
    padding-right: 16px;
  }
  .myContainerempty ul {
    padding-top: 7px;
    font-size: 14px;
  }
  .myContainerempty ul li {
    line-height: 24px;
    padding-bottom: 20px;
    padding-left: 22px;
  }
  .myContainerempty a.butncon {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
  .myContainerempty ul li::before {
    top: 7px;
  }
}
@media screen and (max-width: 640px) {
  .grainger-mobbigbg {
    display: none !important;
  }
  .grainger-mobbg {
    display: block !important;
    height: 85px;
  }
  .aribacont {
    padding: 10px;
    width: 70%;
    height: auto;
  }
  .aribacont h3 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 4px;
  }
  .aribacont p {
    font-size: 11px;
  }
  .aribalogo {
    width: 30%;
    padding: 15px 5px;
    height: auto;
  }
  .aribabanner {
    align-items: stretch;
  }
  .aribacont h3 br {
    display: block;
  }
  .owl-item .aribacont,
  .owl-item .aribalogo {
    height: auto;
  }
  .benefits-of-trade-account .benefits {
    flex-direction: column;
  }
  .benefits-of-trade-account .benefits .benefits-box {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .trade-details:after {
    max-width: 80%;
  }
}
@media screen and (max-width: 480px) {
  .owl-item .aribacont,
  .owl-item .aribalogo {
    height: 70px;
  }
  .grainger-mobbg {
    height: 70px;
  }
  .trade-details a {
    font-size: 10px;
  }
  .trade-details a:after {
    width: 12px;
    height: 12px;
  }
  .trade-details:after {
    max-width: 76%;
    top: -20px;
  }
  .get-a-quote .group label select,
  .talk-quote .get-a-quote input,
  .talk-quote .get-a-quote label,
  .talk-quote .get-a-quote p,
  .talk-quote .get-a-quote textarea {
    font-size: 12px;
  }
  .trade-account .talk-quote .get-a-quote h1:after {
    top: 55px;
  }
  .get-a-quote .group label.country:after {
    top: 44px;
  }
  .terms .checkmark {
    top: 2px;
  }
  .terms {
    padding-left: 25px;
  }
}
@media screen and (max-width: 320px) {
  .aribalogo {
    width: 28%;
  }
  .aribacont {
    width: 72%;
  }
}

.slick-arrow.slick-prev {
  position: absolute;
  left: -25px;
  background: #fff;
}

.slick-arrow.slick-next {
  position: absolute;
  right: -25px;
  background: #fff;
}

a.nav-link.btn-rfq {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  color: #1b2131;
  text-decoration: none;
  padding: 11px 23px 11px 56px;
  display: block;
  border: 1px solid #1b2131;
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  line-height: inherit;
  width: 120px;
  transition: all 0.4s;
}

a.nav-link.btn-rfq:hover {
  border: 1px solid #1a4ab9;
  background-color: #1979c3;
  color: white !important;
}

.role-nav {
  padding: 5px;
  border-radius: 8px;
  background: #e3e3e3;
  transition: color 0.15s ease-in;
}

.searchWrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 22px;
  padding: 0 !important;
  position: relative;
}
.multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 0 !important;
  width: auto !important;
}

.fs-small {
  font-size: 10px;
}

loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.menu-select {
  background: aliceblue;
}

tr.quote td {
  font-size: 12px;
}
.quote-header th {
  font-size: 14px;
}

/* Custom Button */

.custom-btn {
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px !important;
  margin: 10px;

  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* 1 */
.btn-1 {
  background: rgb(6, 14, 131);
  background: linear-gradient(
    0deg,
    rgba(6, 14, 131, 1) 0%,
    rgba(12, 25, 180, 1) 100%
  );
  border: none;
}
.btn-1:hover {
  background: rgb(0, 3, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 3, 255, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
}

/* 2 */
.btn-2 {
  background: rgb(96, 9, 240);
  background: linear-gradient(
    0deg,
    rgba(96, 9, 240, 1) 0%,
    rgba(129, 5, 240, 1) 100%
  );
  border: none;
}
.btn-2:before {
  height: 0%;
  width: 2px;
}
.btn-2:hover {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
}

/* 3 */
.btn-3 {
  background: rgb(0, 172, 238);
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover {
  background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover {
  color: rgba(2, 126, 251, 1);
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}

/* 4 */
.btn-4 {
  background-color: #4dccc6;
  background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-4:hover {
  background-color: #89d8d3;
  background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.btn-4 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:before,
.btn-4:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.9),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  transition: all 0.3s ease;
}
.btn-4:before {
  height: 0%;
  width: 0.1px;
}
.btn-4:after {
  width: 0%;
  height: 0.1px;
}
.btn-4:hover:before {
  height: 100%;
}
.btn-4:hover:after {
  width: 100%;
}
.btn-4 span:before,
.btn-4 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.9),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  transition: all 0.3s ease;
}
.btn-4 span:before {
  width: 0.1px;
  height: 0%;
}
.btn-4 span:after {
  width: 0%;
  height: 0.1px;
}
.btn-4 span:hover:before {
  height: 100%;
}
.btn-4 span:hover:after {
  width: 100%;
}

/* 5 */
.btn-5 {
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  background: rgb(255, 27, 0);
  background: linear-gradient(
    0deg,
    rgba(255, 27, 0, 1) 0%,
    rgba(251, 75, 2, 1) 100%
  );
}
.btn-5:hover {
  color: #f0094a;
  background: transparent;
  box-shadow: none;
}
.btn-5:before,
.btn-5:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #f0094a;
  box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003,
    4px 4px 5px 0px #0002;
  transition: 400ms ease all;
}
.btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}
.btn-5:hover:before,
.btn-5:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

/* 6 */
.btn-6 {
  background: rgb(247, 150, 192);
  background: radial-gradient(
    circle,
    rgba(247, 150, 192, 1) 0%,
    rgba(118, 174, 241, 1) 100%
  );
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
  box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover {
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}
.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 0.5px;
  transition: all 500ms ease;
}
.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 0.5px;
  transition: all 500ms ease;
}
.btn-6 span:hover:before {
  width: 100%;
}
.btn-6 span:hover:after {
  width: 100%;
}

/* 7 */
.btn-7 {
  background: linear-gradient(
    0deg,
    rgba(255, 151, 0, 1) 0%,
    rgba(251, 75, 2, 1) 100%
  );
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-7 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-7:before,
.btn-7:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: rgba(251, 75, 2, 1);
  box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.9),
    -4px -4px 5px 0px rgba(255, 255, 255, 0.9),
    7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}
.btn-7:before {
  height: 0%;
  width: 2px;
}
.btn-7:after {
  width: 0%;
  height: 2px;
}
.btn-7:hover {
  color: rgba(251, 75, 2, 1);
  background: transparent;
}
.btn-7:hover:before {
  height: 100%;
}
.btn-7:hover:after {
  width: 100%;
}
.btn-7 span:before,
.btn-7 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: rgba(251, 75, 2, 1);
  box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.9),
    -4px -4px 5px 0px rgba(255, 255, 255, 0.9),
    7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}
.btn-7 span:before {
  width: 2px;
  height: 0%;
}
.btn-7 span:after {
  height: 2px;
  width: 0%;
}
.btn-7 span:hover:before {
  height: 100%;
}
.btn-7 span:hover:after {
  width: 100%;
}

/* 8 */
.btn-8 {
  background-color: #f0ecfc;
  background-image: linear-gradient(315deg, #f0ecfc 0%, #c797eb 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-8 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-8:before,
.btn-8:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.btn-8:before {
  height: 0%;
  width: 2px;
}
.btn-8:after {
  width: 0%;
  height: 2px;
}
.btn-8:hover:before {
  height: 100%;
}
.btn-8:hover:after {
  width: 100%;
}
.btn-8:hover {
  background: transparent;
}
.btn-8 span:hover {
  color: #c797eb;
}
.btn-8 span:before,
.btn-8 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.btn-8 span:before {
  width: 2px;
  height: 0%;
}
.btn-8 span:after {
  height: 2px;
  width: 0%;
}
.btn-8 span:hover:before {
  height: 100%;
}
.btn-8 span:hover:after {
  width: 100%;
}

/* 9 */
.btn-9 {
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}
.btn-9:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1fd1f9;
  background-image: linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%);
  transition: all 0.3s ease;
}
.btn-9:hover {
  background: transparent;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  color: #fff;
}
.btn-9:hover:after {
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}

/* 10 */
.btn-10 {
  background: rgb(22, 9, 240);
  background: linear-gradient(
    0deg,
    rgba(22, 9, 240, 1) 0%,
    rgba(49, 110, 244, 1) 100%
  );
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}
.btn-10:after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
}
.btn-10:hover {
  color: #fff;
  border: none;
  background: transparent;
}
.btn-10:hover:after {
  background: rgb(0, 3, 255);
  background: linear-gradient(
    0deg,
    rgba(2, 126, 251, 1) 0%,
    rgba(0, 3, 255, 1) 100%
  );
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* 11 */
.btn-11 {
  border: none;
  background: rgb(251, 33, 117);
  background: linear-gradient(
    0deg,
    rgba(251, 33, 117, 1) 0%,
    rgba(234, 76, 137, 1) 100%
  );
  color: #fff;
  overflow: hidden;
}
.btn-11:hover {
  text-decoration: none;
  color: #fff;
}
.btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:hover {
  opacity: 0.7;
}
.btn-11:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

/* 12 */
.btn-12 {
  position: relative;
  right: 20px;
  bottom: 20px;
  border: none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.btn-12 span {
  background: rgb(0, 172, 238);
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-12 span:nth-child(1) {
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.btn-12:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

/* 13 */
.btn-13 {
  background-color: #89d8d3;
  background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
  border: none;
  z-index: 1;
}
.btn-13:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  background-color: #4dccc6;
  background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.btn-13:hover {
  color: #fff;
}
.btn-13:hover:after {
  top: 0;
  height: 100%;
}
.btn-13:active {
  top: 2px;
}

/* 14 */
.btn-14 {
  background: rgb(255, 151, 0);
  border: none;
  z-index: 1;
}
.btn-14:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  background-color: #eaf818;
  background-image: linear-gradient(315deg, #eaf818 0%, #f6fc9c 74%);
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5) 7px 7px 20px 0px
      rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.btn-14:hover {
  color: #000;
}
.btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.btn-14:active {
  top: 2px;
}

/* 15 */
.btn-15 {
  background: #b621fe;
  border: none;
  z-index: 1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #663dff;
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}

/* 16 */
.btn-16 {
  border: none;
  color: #000;
}
.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.btn-16:hover {
  color: #000;
}
.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.btn-16:active {
  top: 2px;
}

/* End Custom Button */
@media only screen and (min-width: 150px) {
  .paypal-button-container {
    min-width: 150px;
    max-width: 200px;
    min-height: 50px;
  }
}

.paypal-button-row {
  height: 50px !important;
  vertical-align: top;
  min-height: 25px;
  max-height: 70px !important;
}
.paypal-button-number-0.paypal-button-layout-horizontal.paypal-button-shape-rect.paypal-button-number-single.paypal-button-env-sandbox.paypal-button-color-gold.paypal-button-text-color-black.paypal-logo-color-blue {
  height: 50px !important;
}

.paypal-button-row.paypal-button-number-0.paypal-button-layout-horizontal.paypal-button-shape-rect.paypal-button-number-single.paypal-button-env-sandbox.paypal-button-color-gold.paypal-button-text-color-black.paypal-logo-color-blue {
  height: 50px !important;
}

@media only screen and (min-width: 150px) {
  .paypal-button-row {
    height: 50px !important;
    vertical-align: top;
    min-height: 25px;
    max-height: 55px;
  }
}

footer.footer-bottom.bb-footer.style-2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.bb-footer {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #242424;
  font-size: 13px;
  color: #666666;
}

.bb-footer {
  padding: 0 10px;
}

.footer-bottom-left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bb-footer.style-2 .footer-bottom-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.footer-bottom-right {
  padding-left: 20px;
  max-width: 30%;
}
.push-right {
  margin-left: auto;
}
.push-right {
  margin-left: auto;
}
.footer-logo-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: 15px;
}

.footer-copyright-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-top: 5px;
}

.bb-footer.style-2 .footer-socials {
  order: inherit;
}
.bb-footer.style-2 .footer-socials {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.bb-footer .footer-socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 0 0 auto;
  list-style: none;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer-copyright-wrap .footer-menu {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.bb-footer .footer-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  justify-content: center;
}

.bb-footer .footer-socials {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  justify-content: center;
}

.bb-footer .footer-socials li:first-child a {
  margin-left: 0;
}
.bb-footer .footer-socials a {
  display: block;
  line-height: 1;
  margin: 6px;
}
.bb-footer li a {
  font-size: 20px;
  letter-spacing: -0.24px;
}

.bb-footer .footer-socials i {
  font-size: 24px;
  font-weight: 200;
  color: #666666;
}

.footer-desc a {
  color: #666666;
}

.bb-footer .footer-socials i:hover {
  color: #ff2c57;
}

.footer-desc a:hover {
  color: #ff2c57;
}

.bb-footer li a:hover {
  color: #ff2c57 !important;
}

.client-pic{
  top: 30%;
  left: -8%;
}


